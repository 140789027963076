import React, { lazy, ReactElement, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { LoadingSpinner } from "@components/routes/utils";

const PricesAndTaxesListContainer = lazy(
  () => import("@components/accountingItemsPricesAndTaxes/AccountingItemsAndTaxesLayout")
);

const PricesAndTaxesListPage = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/"
          element={<Outlet />}
        >
          <Route
            path=""
            element={<PricesAndTaxesListContainer />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PricesAndTaxesListPage;
