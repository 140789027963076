import { useState } from "react";
import { CollapseRows } from "@components/report/financial-report-table/FinancialReportTableBody";
import useNotifier from "@hooks/useNotifier";
import { Report } from "@components/report/hooks/report-layout.hook";
import useCreateFinancialReportXlsx from "@hooks/reports/create-financial-report-xlsx.hook";
import { downloadFile } from "@utils/files";

type UseFinancialReportTable = {
  collapseRows: CollapseRows;
  xlsxFileIsPending: boolean;
  handleClickOnCollapseRow: (key: string) => void;
  handleClickExportFinancialReportToXlsx: () => void;
};

const useFinancialReportTable = (reportSettings: Report, customerId: string): UseFinancialReportTable => {
  const [collapseRows, setCollapseRows] = useState<CollapseRows>({});
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const { mutate: exportXlsxFile, isPending: xlsxFileIsPending } = useCreateFinancialReportXlsx(
    customerId,
    reportSettings
  );
  const { enqueueMessage } = useNotifier();

  const handleClickOnCollapseRow = (key: string): void => {
    collapseRows[key] = !collapseRows[key];
    setOpenCollapse((prevState) => !prevState);
    setCollapseRows(collapseRows);
  };

  const handleClickExportFinancialReportToXlsx = (): void => {
    exportXlsxFile().then((response) => {
      if (response) {
        downloadFile(new Blob([response]), `rapport_financier.xlsx`);
        enqueueMessage("Fichier .xlsx de rapport financier téléchargé avec succès", "success");
      } else {
        enqueueMessage(
          "Une erreur est survenue lors du téléchargement du fichier .xlsx de rapport financier.",
          "error"
        );
      }
    });
  };

  return {
    collapseRows,
    xlsxFileIsPending,
    handleClickOnCollapseRow,
    handleClickExportFinancialReportToXlsx,
  };
};

export default useFinancialReportTable;
