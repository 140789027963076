import React, { ReactElement } from "react";
import { Button } from "@ui/button/Button";
import Icon from "@components/icons/Icon";
import { ModalCloseButton } from "@ui/modal/Modal.styles";

export interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton = ({ onClose }: CloseButtonProps): ReactElement => (
  <ModalCloseButton>
    <Button
      onClick={onClose}
      variant="text"
      isIcon
    >
      <Icon
        name="close"
        size="small"
      />
    </Button>
  </ModalCloseButton>

);

export default CloseButton;
