import React, { createContext, ReactElement, useEffect, useMemo } from "react";
import useFetchFeatureFlags from "@hooks/feature-flags/fetch-feature-flags-list.hook";
import { FeatureFlags } from "@@types/feature-flags/feature-flag.type";

type ContextState = {
  featureFlags: FeatureFlags;
};

export const FeatureFlagsContext = createContext({} as ContextState);

type Props = {
  children: ReactElement;
};

function FeatureFlagsProvider({ children }: Props): ReactElement {
  const { data, isLoading } = useFetchFeatureFlags();
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlags>({} as FeatureFlags);

  useEffect(() => {
    if (data && !isLoading) {
      setFeatureFlags(data);
    }
  }, [isLoading, data]);

  const value: ContextState = useMemo(
    () => ({
      featureFlags,
    }),
    [featureFlags],
  );

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

function useFeatureFlagsContext(): ContextState {
  const context = React.useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error("useFeatureFlagsContext must be used within a FeatureFlagsProvider");
  }
  return context as unknown as ContextState;
}

/**
 * Children component
 * @param Component
 */
function withFeatureFlagsContext<T extends React.JSX.IntrinsicAttributes = React.JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>,
): React.ComponentType<T> {
  return function fn(props: T) {
    return (
      <FeatureFlagsProvider>
        <Component {...props} />
      </FeatureFlagsProvider>
    );
  };
}

export { FeatureFlagsProvider, withFeatureFlagsContext, useFeatureFlagsContext };
