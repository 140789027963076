import { useMutation } from "@tanstack/react-query";
import { Report } from "@components/report/hooks/report-layout.hook";
import createFinancialReportXlsx from "../../services/reports/create-financial-report-xlsx.service";

export type UseCreateFinancialReportXlsx = {
  mutate: () => Promise<string>;
  isPending: boolean;
};

function useCreateFinancialReportXlsx(customerId: string, reportSettings: Report): UseCreateFinancialReportXlsx {
  const mutation = useMutation<string>({
    mutationFn: (): Promise<string> => createFinancialReportXlsx(reportSettings, customerId),
  });

  const mutate = async (): Promise<string> => {
    const response = await mutation.mutateAsync();
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateFinancialReportXlsx;
