import React, { ReactElement, useState } from "react";
import { Collapse, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { List } from "@components/header/Menu";
import { useFeatureFlagsContext } from "@context/featureFlags/FeatureFlagsContext";
import Icon from "@components/icons/Icon";
import { COLORS } from "@styles/colors";
import {
  useAdministrativeSectorsContext,
  withAdministrativeSectorsContext,
} from "@context/geo/AdministrativeSectorsContext";
import { Link } from "react-router-dom";
import { PEP_ROUTES, ROUTES_PARAMETERS } from "@components/routing/constants";
import { ROUTES } from "@components/routing/RouterV2";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useListCodesContext } from "@context/list-codes/ListCodesContext";

export type PepMenuItemsProps = {
  closeMenu: () => void;
};
const PepMenuItems = ({ closeMenu }: PepMenuItemsProps): ReactElement | null => {
  const { featureFlags } = useFeatureFlagsContext();
  const { matterListCodes } = useListCodesContext();
  const { administrativeSectors } = useAdministrativeSectorsContext();
  const [open, setOpen] = useState(false);

  if (!featureFlags["access-linea-geo-api-web-pages"]) {
    return null;
  }

  return (
    <List>
      {/* Routes dropdown button */}
      <ListItemButton onClick={() => setOpen((prevState) => !prevState)}>
        <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
          <Icon
            name="trash"
            size="medium"
            fillColor={COLORS.bleuVilleco}
          />
        </ListItemIcon>
        <ListItemText primary={"PEP"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse
        key={1}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List
          disablePadding
          onClick={() => setOpen((prevState) => !prevState)}
          style={{
            overflow: "auto",
            maxHeight: 300,
          }}
        >
          {Object.values(administrativeSectors).map((administrativeSector) => {
            return (
              <>
                <ListItemText
                  key={administrativeSector.id}
                  primary={administrativeSector.aliasName}
                />
                {Object.values(matterListCodes).map((matter) => (
                  <ListItemButton
                    key={matter.id}
                    component={Link}
                    to={`${ROUTES.pep}${
                      PEP_ROUTES.administrativeSectors
                    }/${ROUTES_PARAMETERS.administrativeSectorId.replace(
                      ROUTES_PARAMETERS.administrativeSectorId,
                      administrativeSector.id
                    )}${PEP_ROUTES.sectorDetailsMasterCollectionSectors.replace(
                      ROUTES_PARAMETERS.matterCode,
                      matter.code
                    )}`}
                    onClick={closeMenu}
                  >
                    <ListItemText primary={matter.description} />
                  </ListItemButton>
                ))}
              </>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default withAdministrativeSectorsContext<PepMenuItemsProps>(PepMenuItems);
