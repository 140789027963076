// React imports
import React, { ReactElement } from "react";
// Externals imports

import { FinancialReportData } from "@@types/reports/financial-report.type";
import FinancialReportParentRow from "@components/report/financial-report-table/FinancialReportParentRow";
import FinancialReportChildRow from "@components/report/financial-report-table/FinancialReportChildRow";
import FinancialReportTotalRow from "@components/report/financial-report-table/FinancialReportTotalRow";
import { TableCell } from "@mui/material";

export type CollapseRows = {
  [key: string]: boolean;
};

type FinancialReportTableBodyProps = {
  isLoading: boolean;
  report: FinancialReportData[];
  collapseRows: CollapseRows;
  onClick: (key: string) => void;
};

const FinancialReportTableBody = ({
  isLoading,
  collapseRows,
  report,
  onClick,
}: FinancialReportTableBodyProps): ReactElement | ReactElement[] => {
  if (!isLoading && !report) {
    return <span>** Aucune donnée disponible **</span>;
  }

  return report.map((row) => {
    const key = `${row.contractName}-${row.type}-${row.customerItemName}-${row.flow}-${row.dropPoint}`;
    return (
      <React.Fragment key={key}>
        <FinancialReportParentRow
          key={key}
          row={row}
          collapseRow={collapseRows[key]}
          onClick={() => onClick(key)}
        />

        {collapseRows[key] && (
          <TableCell colSpan={8}>
            <FinancialReportChildRow
              key={key}
              datesWithEventsCount={row.datesWithEventsCount}
            />
            <FinancialReportTotalRow total={row.total} />
          </TableCell>
        )}
      </React.Fragment>
    );
  });
};

export default FinancialReportTableBody;
