import React, { ReactElement } from "react";
import { FormControl, Input, InputLabel, ListItem } from "@mui/material";
import { DateWithEventsCount } from "@@types/reports/financial-report.type";

type FinancialReportChildRowProps = {
  key: string;
  datesWithEventsCount: DateWithEventsCount[];
};

const FinancialReportChildRow = ({ key, datesWithEventsCount }: FinancialReportChildRowProps): ReactElement[] => {
  return datesWithEventsCount.map((dateWithEventsCount) => {
    return (
      <ListItem
        key={key}
        divider
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <FormControl variant="standard">
          <InputLabel>Date</InputLabel>
          <Input
            disabled
            value={dateWithEventsCount.date}
            disableUnderline
            sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px" }}
          />
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ width: "110px" }}
        >
          <InputLabel>Quantité</InputLabel>
          <Input
            disabled
            value={dateWithEventsCount.count}
            disableUnderline
            sx={{ "input.Mui-disabled": { WebkitTextFillColor: "black" }, fontSize: "14px", paddingLeft: 2 }}
          />
        </FormControl>
      </ListItem>
    );
  });
};

export default FinancialReportChildRow;
