import {
  AdministrativeSector,
  AdministrativeSectorDetail,
  AdministrativeSectorDetails,
  GeoAdministrativeSector,
} from "@@types/administrative-sectors/administrative-sector.type";
import { mapResponsesToSectorDetails } from "@@types/sector-details/mappers/mapResponseToSectorDetail.mapper";
import { mapResponsesToSectors } from "@@types/sectors/mappers/mapResponseToSector.mapper";

export function mapResponseToAdministrativeSector(response: GeoAdministrativeSector): AdministrativeSector {
  return {
    id: response.id,
    name: response.name,
    handle: response.handle,
    aliasName: response.aliasName,
  };
}

export function mapResponsesToAdministrativeSectors(responses: GeoAdministrativeSector[]): AdministrativeSector[] {
  return responses.map(mapResponseToAdministrativeSector);
}

export function mapResponseToAdministrativeSectorDetail(response: GeoAdministrativeSector): AdministrativeSectorDetail {
  return {
    ...mapResponseToAdministrativeSector(response),
    sectorDetails: mapResponsesToSectorDetails(response.sectorDetails),
    sectors: mapResponsesToSectors(response.sectors),
  };
}

export function mapResponsesToAdministrativeSectorDetails(
  responses: GeoAdministrativeSector[]
): AdministrativeSectorDetails {
  try {
    return responses.reduce(
      (acc: AdministrativeSectorDetails, response: GeoAdministrativeSector): AdministrativeSectorDetails => {
        acc[response.id] = mapResponseToAdministrativeSectorDetail(response);
        return acc;
      },
      {} as AdministrativeSectorDetails
    );
  } catch (error) {
    console.error(error);
    return {};
  }
}
