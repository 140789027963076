import { useMutation } from "@tanstack/react-query";
import { MutationResult } from "@@types/apiResponse";
import { Report } from "@components/report/hooks/report-layout.hook";
import { ProblemCaseReport } from "@@types/reports/problem-case-report.type";
import createProblemCasesReport from "../../services/reports/create-problem-cases-report.service";

export type UseCreateFinancialReport = {
  mutate: (reportSettings: Report) => Promise<MutationResult<ProblemCaseReport[]>>;
  isPending: boolean;
};

function useCreateProblemCasesReport(customerId: string): UseCreateFinancialReport {
  const mutation = useMutation<MutationResult<ProblemCaseReport[]>, Error, Report>({
    mutationFn: (reportSettings: Report): Promise<MutationResult<ProblemCaseReport[]>> =>
      createProblemCasesReport(reportSettings, customerId),
  });

  const mutate = async (reportSettings: Report): Promise<MutationResult<ProblemCaseReport[]>> => {
    return await mutation.mutateAsync(reportSettings);
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateProblemCasesReport;
