import React, { ReactElement, useState } from "react";
import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MasterRouteReport } from "@@types/reports/master-route-report.type";
import MasterRoutesReportModalityTableHeader from "@components/report/master-routes-report-table/MasterRoutesReportModalityTableHeader";
import MasterRoutesReportChildRow from "@components/report/master-routes-report-table/MasterRoutesReportChildRow";

type MasterRoutesReportParentRowProps = {
  key: string;
  row: MasterRouteReport;
};

const MasterRoutesReportParentRow = React.memo(({ key, row }: MasterRoutesReportParentRowProps): ReactElement => {
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);

  const handleClickOnCollapseRow = (): void => {
    setOpenCollapse((prevState) => !prevState);
  };

  return (
    <>
      <TableRow
        key={key}
        sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
        onClick={handleClickOnCollapseRow}
      >
        <TableCell>{row.fileNumber}</TableCell>
        <TableCell>{row.companyName}</TableCell>
        <TableCell>{row.doorNo}</TableCell>
        <TableCell>{row.street}</TableCell>
        <TableCell>{row.city}</TableCell>
        <TableCell>{row.postalCode}</TableCell>
        <TableCell>{row.dechets.isSemA || row.dechets.isSemB}</TableCell>
        <TableCell>{row.recyclage.isSemA || row.recyclage.isSemB}</TableCell>
        <TableCell sx={{ paddingRight: 3 }}>
          <IconButton key={key}>{openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </TableCell>
      </TableRow>

      {openCollapse && (
        <TableRow
          key={row.fileNumber}
          sx={{
            backgroundColor: "#f5f5f5 !important",
            "*:nth-of-type(even)": { backgroundColor: "#f5f5f5 !important" },
          }}
        >
          <TableCell colSpan={9}>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6">Modalité(s)</Typography>
              <MasterRoutesReportModalityTableHeader />
              <MasterRoutesReportChildRow
                dechets={row.dechets}
                recyclage={row.recyclage}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
});

export default MasterRoutesReportParentRow;
