// React imports
import React, { memo } from "react";

// Internal imports
import { withProvider } from "@utils/withProvider";
import CustomerContextProvider from "@context/customer/CustomerContextProvider";

// External imports
import Select from "react-select";
import { isEqual, find, orderBy } from "lodash";
import { Button, ButtonGroup, Stack } from "@mui/material";

const handleChangeCustomer = async (customer, setSingleCurrent) => {
  setSingleCurrent("customer", { ...customer, error: false });
};

function CustomerSelect({ isError, customer, customers, handlers }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      mt="1px"
    >
      <Select
        styles={{
          container: (base) => ({ ...base, flex: 1 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            boxShadow: isError ? "none" : base.boxShadow,
            borderColor: isError ? "red" : base.borderColor,
            "&:hover": {
              borderColor: isError ? "red" : base["&:hover"].borderColor,
            },
          }),
        }}
        value={customer.value ? customer : null}
        isLoading={customers.loading}
        isDisabled={customers.loading}
        menuPosition="fixed"
        onChange={(customer) => handleChangeCustomer(customer, handlers.setSingleCurrent)}
        options={customers.data}
        placeholder={"Sélectionner un client"}
      />
    </Stack>
  );
}

const areEqual = (prevProps, nextProps) => {
  const { isError: prevIsError, customer: prevCustomer, customers: prevCustomers } = prevProps;
  const { isError: nextIsError, customer: nextCustomer, customers: nextCustomers } = nextProps;
  let arePropsEqual = true;

  // If the customers array changed, rerender
  if (!isEqual(prevCustomers, nextCustomers)) {
    arePropsEqual = false;
  }

  // If the current customer changed, rerender
  if (!isEqual(prevCustomer, nextCustomer)) {
    arePropsEqual = false;
  }

  // If the error changed, rerender
  if (!isEqual(prevIsError, nextIsError)) {
    arePropsEqual = false;
  }

  return arePropsEqual;
};

export default withProvider(CustomerContextProvider)(memo(CustomerSelect, areEqual));
