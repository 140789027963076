import { QueryKey } from "@tanstack/react-query";

const customer = {
  all: (): QueryKey => ["customer"],
  lists: (): QueryKey => [...customer.all(), "list"],
  list: (filters: object): QueryKey => [...customer.lists(), { filters }],
  details: (): QueryKey => [...customer.all(), "detail"],
  detail: (id: string): QueryKey => [...customer.details(), id],
};

export default customer;
