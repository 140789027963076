import { useMutation } from "@tanstack/react-query";
import { MutationResult } from "@@types/apiResponse";
import { Report } from "@components/report/hooks/report-layout.hook";
import { MasterRouteReport } from "@@types/reports/master-route-report.type";
import createMasterRoutesReport from "../../services/reports/create-master-routes-report.service";

export type UseCreateFinancialReport = {
  mutate: (reportSettings: Report) => Promise<MutationResult<MasterRouteReport[]>>;
  isPending: boolean;
};

function useCreateMasterRoutesReport(customerId: string): UseCreateFinancialReport {
  const mutation = useMutation<MutationResult<MasterRouteReport[]>, Error, Report>({
    mutationFn: (reportSettings: Report): Promise<MutationResult<MasterRouteReport[]>> =>
      createMasterRoutesReport(reportSettings, customerId),
  });

  const mutate = async (reportSettings: Report): Promise<MutationResult<MasterRouteReport[]>> => {
    return await mutation.mutateAsync(reportSettings);
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateMasterRoutesReport;
