import { useMutation } from "@tanstack/react-query";
import { Report } from "@components/report/hooks/report-layout.hook";
import createProblemCasesReportPhotos from "../../services/reports/create-problem-cases-report-photos.service";

export type UseCreateProblemCasesReportPhotos = {
  mutate: () => Promise<string>;
  isPending: boolean;
};

function useCreateProblemCasesReportPhotos(
  customerId: string,
  reportSettings: Report
): UseCreateProblemCasesReportPhotos {
  const mutation = useMutation<string>({
    mutationFn: (): Promise<string> => createProblemCasesReportPhotos(reportSettings, customerId),
  });

  const mutate = async (): Promise<string> => {
    const response = await mutation.mutateAsync();
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateProblemCasesReportPhotos;
