import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AdministrativeSectorDetails } from "@@types/administrative-sectors/administrative-sector.type";
import { administrativeSectorsKeys } from "@hooks/administrative-sectors/administrative-sectors.keys";
import { AdministrativeSectorsService } from "../../services/administrative-sectors/administrative-sectors.service";
import { mapResponsesToAdministrativeSectorDetails } from "@@types/administrative-sectors/mappers/mapResponseToAdministrativeSector.mapper";

const useFetchAdministrativeSectors = (): UseQueryResult<AdministrativeSectorDetails> => {
  return useQuery({
    queryKey: administrativeSectorsKeys.list(),
    queryFn: () => AdministrativeSectorsService.fetchAdministrativeSectors(),
    select: (data) => mapResponsesToAdministrativeSectorDetails(data.data.administrativeSectors),
  });
};

export default useFetchAdministrativeSectors;
