import React, { ReactElement } from "react";
import { Paper, Stack, Table, TableBody, TableContainer, TableHead } from "@mui/material";

import { ProblemCaseReport } from "@@types/reports/problem-case-report.type";
import ProblemCasesReportTableHeader from "@components/report/problem-cases-report-table/ProblemCasesReportTableHeader";
import ProblemCasesReportTableBody from "@components/report/problem-cases-report-table/ProblemCasesReportTableBody";
import ExportReportToXlsxButton from "@components/report/buttons/ExportReportToXlsxButton";
import useProblemCasesReportTable from "@components/report/hooks/problem-cases-report-table.hook";
import { Report } from "@components/report/hooks/report-layout.hook";
import { Customer } from "@@types/customers/customer.type";

type ProblemCasesReportTableProps = {
  customer: Customer;
  report: ProblemCaseReport[];
  reportSettings: Report;
  isLoading: boolean;
};

const ProblemCasesReportTable = ({
  customer,
  report,
  reportSettings,
  isLoading,
}: ProblemCasesReportTableProps): ReactElement => {
  const {
    handleClickExportProblemCasesReportToXlsx,
    handleClickExportVdqProblemCasesReportToXlsx,
    handleClickExportProblemCasesReportPhotos,
    xlsxFileIsPending,
    vdqXlsxFileIsPending,
    photosIsPending,
  } = useProblemCasesReportTable(reportSettings, customer.id.toString());
  return (
    <TableContainer component={Paper}>
      <Stack
        direction="row"
        spacing={2}
        pl="36px"
      >
        {!isLoading && (
          <ExportReportToXlsxButton
            onClick={handleClickExportProblemCasesReportToXlsx}
            isLoading={xlsxFileIsPending}
            label={"Télécharger rapport cas problèmes"}
          />
        )}

        {!isLoading && customer.isFromCiWeb && (
          <ExportReportToXlsxButton
            onClick={handleClickExportVdqProblemCasesReportToXlsx}
            isLoading={vdqXlsxFileIsPending}
            label={"Télécharger rapport cas problèmes VDQ"}
          />
        )}

        {!isLoading && (
          <ExportReportToXlsxButton
            onClick={handleClickExportProblemCasesReportPhotos}
            isLoading={photosIsPending}
            label={"Télécharger photos cas problèmes"}
          />
        )}
      </Stack>
      <Table aria-label="collapsible table">
        <TableHead>
          <ProblemCasesReportTableHeader />
        </TableHead>
        <TableBody>
          <ProblemCasesReportTableBody
            report={report}
            isLoading={isLoading}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProblemCasesReportTable;
