// React imports
import React, { ReactElement, ReactNode } from "react";
// External imports
import { TableCell, TableRow } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: ReactNode };
};

const headerLabel: HeaderLabel = {
  quantity: { label: "Quantité" },
  formats: { label: "Format(s)" },
  isSemA: { label: "Sem. A" },
  isSemB: { label: "Sem. B" },
  monday: { label: "Lundi" },
  tuesday: { label: "Mardi" },
  wednesday: { label: "Mercredi" },
  thursday: { label: "Jeudi" },
  friday: { label: "Vendredi" },
};

const header: string[] = [
  "quantity",
  "formats",
  "isSemA",
  "isSemB",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
];

const MasterRoutesReportModalityTableHeader = (): ReactElement => {
  return (
    <TableRow>
      <TableCell />
      {header.map((name) => (
        <TableCell key={name}>{headerLabel[name].label}</TableCell>
      ))}
    </TableRow>
  );
};

export default MasterRoutesReportModalityTableHeader;
