import { MutationResult } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { Report } from "@components/report/hooks/report-layout.hook";
import { FinancialReportData } from "@@types/reports/financial-report.type";

const createFinancialReport = async (
  reportSettings: Report,
  customerId: string
): Promise<MutationResult<FinancialReportData[]>> => {
  const res = await http.post<MutationResult<FinancialReportData[]>>(`/reports/financial_report`, {
    reportSettings,
    customerId,
  });
  return res.data;
};

export default createFinancialReport;
