import { axiosClientV2 } from "../axios";
import { requestOptions } from "../auth";
import { Report } from "@components/report/hooks/report-layout.hook";

const responseType = "blob";

const createFinancialReportXlsx = async (reportSettings: Report, customerId: string): Promise<string> => {
  try {
    const res = await axiosClientV2.post<string>(
      `/reports/financial_report_to_xlsx`,
      { reportSettings, customerId },
      requestOptions(responseType)
    );
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default createFinancialReportXlsx;
