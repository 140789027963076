import React, { ReactElement } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { MasterRouteReportModality } from "@@types/reports/master-route-report.type";

type MasterRoutesReportChildRowProps = {
  dechets: MasterRouteReportModality;
  recyclage: MasterRouteReportModality;
};

const MasterRoutesReportChildRow = ({ dechets, recyclage }: MasterRoutesReportChildRowProps): ReactElement => {
  const modalities = [dechets, recyclage].filter((modality) => modality.isSemA || modality.isSemB);
  return (
    <TableBody>
      {modalities.map((modality, index) => (
        <TableRow key={index}>
          <TableCell>{modality.matter}</TableCell>
          <TableCell>{modality.quantity}</TableCell>
          <TableCell>{modality.formats}</TableCell>
          <TableCell>{modality.isSemA}</TableCell>
          <TableCell>{modality.isSemB}</TableCell>
          <TableCell>{modality.monday}</TableCell>
          <TableCell>{modality.tuesday}</TableCell>
          <TableCell>{modality.wednesday}</TableCell>
          <TableCell>{modality.thursday}</TableCell>
          <TableCell>{modality.friday}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default MasterRoutesReportChildRow;
