import React, { ReactElement, ReactNode } from "react";
import { SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { CustomerContract } from "@@types/customers/customer.type";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

type ReportTypeSelectProps = {
  contracts: CustomerContract[];
  selectedContracts: number[];
  selectAllContracts: boolean;
  onChange: (event: SelectChangeEvent<number[]>, child: ReactNode) => void;
  selectedReportType: string;
};

const ReportContractsSelect = ({
  contracts,
  selectedContracts,
  selectAllContracts,
  onChange,
  selectedReportType,
}: ReportTypeSelectProps): ReactElement => {
  const renderValues = (value: number[]): ReactNode => {
    const nbOfContracts = contracts?.length || 0;
    const selectedContractsCount = value?.length || 0;
    const contractsCounter = `(${selectedContractsCount}/${nbOfContracts})`;

    switch (true) {
      case selectedReportType != "1":
        return `Aucune sélection de contrat nécessaire`;
      case nbOfContracts == 0:
        return `Aucun Contrat`;
      case selectedContractsCount == nbOfContracts:
        return `Tous les Contrats sont sélectionnés`;
      case selectedContractsCount == 0:
        return `Aucun Contrat sélectionné`;
      default:
        return `Contrats sélectionnés ${contractsCounter}`;
    }
  };

  return (
    <FormControl sx={{ width: 400 }}>
      <InputLabel shrink>Contrats</InputLabel>
      <Select
        displayEmpty
        disabled={selectedReportType != "1" || contracts.length == 0}
        multiple
        notched
        label="Contrats"
        value={selectedContracts}
        onChange={onChange}
        renderValue={renderValues}
        MenuProps={MenuProps}
      >
        <MenuItem value="allContracts">
          <Checkbox
            value="allContracts"
            checked={selectAllContracts || selectedContracts.length === contracts.length}
            onChange={onChange}
            indeterminate={selectedContracts.length > 0 && selectedContracts.length < contracts.length}
          />
          <ListItemText primary="Tout sélectionner" />
        </MenuItem>
        {contracts.map((contract) => {
          return (
            <MenuItem
              key={contract.id.toString()}
              value={contract.id}
            >
              <Checkbox checked={selectedContracts.indexOf(contract.id) > -1} />
              <ListItemText primary={contract.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ReportContractsSelect;
