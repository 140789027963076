import { Customer } from "@@types/customers/customer.type";
import useFetchShowCustomer from "@hooks/customers/fetch-customer.hook";
import useCustomParams from "@hooks/useCustomParams";

type UseLocationContainer = {
  customer: Customer;
  isPending: boolean;
};

const useReportContainer = (): UseLocationContainer => {
  const { customerId } = useCustomParams();
  const { data: customer, isPending } = useFetchShowCustomer(customerId);
  return {
    customer,
    isPending,
  } as UseLocationContainer;
};

export default useReportContainer;
