import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { FeatureFlagResponse, FeatureFlags } from "@@types/feature-flags/feature-flag.type";
import FeatureFlagsKeys from "@hooks/feature-flags/feature-flags.keys";
import FeatureFlagsService from "../../services/feature-flags/FeatureFlagsService";

const useFetchFeatureFlags = (): UseQueryResult<FeatureFlags> => {
  return useQuery({
    queryKey: FeatureFlagsKeys.all(),
    queryFn: () => FeatureFlagsService.fetchList(),
    select: ({ data: response }): FeatureFlags => {
      if (response.data) {
        const featureFlags = response.data;
        return featureFlags.reduce((acc: FeatureFlags, flag: FeatureFlagResponse) => {
          acc[flag.handle] = flag.enabled;
          return acc;
        }, {} as FeatureFlags);
      }

      return {} as FeatureFlags;
    },
  });
};

export default useFetchFeatureFlags;
