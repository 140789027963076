import { useMutation } from "@tanstack/react-query";
import { MutationResult } from "@@types/apiResponse";
import { Report } from "@components/report/hooks/report-layout.hook";
import createFinancialReport from "../../services/reports/create-financial-report.service";
import { FinancialReportData } from "@@types/reports/financial-report.type";

export type UseCreateFinancialReport = {
  mutate: (reportSettings: Report) => Promise<MutationResult<FinancialReportData[]>>;
  isPending: boolean;
};

function useCreateFinancialReport(customerId: string): UseCreateFinancialReport {
  const mutation = useMutation<MutationResult<FinancialReportData[]>, Error, Report>({
    mutationFn: (reportSettings: Report): Promise<MutationResult<FinancialReportData[]>> =>
      createFinancialReport(reportSettings, customerId),
  });

  const mutate = async (reportSettings: Report): Promise<MutationResult<FinancialReportData[]>> => {
    return await mutation.mutateAsync(reportSettings);
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateFinancialReport;
