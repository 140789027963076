import React, { lazy, ReactElement, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { LoadingSpinner } from "@components/routes/utils";
import withQueryParams from "@context/withQueryParams";

const RollOffLiftingRequestsContainer = lazy(() => import("@components/roll-off-lifting-requests/RollOffLiftingRequestsContainer"));

const RollOffLiftingRequestsPage = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/"
          element={<Outlet />}
        >
          <Route
            path=""
            element={<RollOffLiftingRequestsContainer />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default withQueryParams(RollOffLiftingRequestsPage);
