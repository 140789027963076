// React imports
import React, { ChangeEvent, ReactElement } from "react";
import { Button, Grid, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import DeleteIcon from "@mui/icons-material/Delete";
import { NotificationContacts } from "@@types/locations/location.type";

type SmsAndEmailNotificationsProps = {
  notificationContacts: NotificationContacts;
  customerMobilePhoneNumber: string;
  addNotificationButtonDisabled: boolean;
  copyCellphoneNumberDisabled: boolean;
  setSingleCurrent: (state: string, value: unknown, nestedState?: string) => void;
  setSingleNestedCurrent: (state: string, index: number, nestedState: string, value: unknown) => void;
};

const SmsAndEmailNotifications = ({
  notificationContacts,
  customerMobilePhoneNumber,
  addNotificationButtonDisabled,
  copyCellphoneNumberDisabled,
  setSingleCurrent,
  setSingleNestedCurrent,
}: SmsAndEmailNotificationsProps): ReactElement => {
  const handleClickAddNotification = (): void => {
    const newKey = parseInt(Object.keys(notificationContacts)[Object.keys(notificationContacts).length - 1]) + 1;

    setSingleCurrent("notificationContacts", {
      ...notificationContacts,
      [newKey]: {
        id: null,
        name: "",
        smsOrEmail: "",
        error: false,
        delete: false,
      },
    });
  };

  const handleClickRemoveNotification = (index: number): void => {
    const { [index]: valueToRemove, ...rest } = notificationContacts;

    if (valueToRemove.id !== null) {
      setSingleNestedCurrent("notificationContacts", index, "delete", true);
    } else {
      setSingleCurrent("notificationContacts", rest);
    }
  };

  const handleClickCopyCellPhoneNumber = (): void => {
    setSingleNestedCurrent("notificationContacts", 0, "smsOrEmail", customerMobilePhoneNumber);
  };

  const handleChangeNotificationContactsValue = (
    index: number,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setSingleNestedCurrent("notificationContacts", index, event.target.name, event.target.value);
    setSingleNestedCurrent("notificationContacts", index, "error", false);
  };

  return (
    <Stack
      spacing={2}
      pt={2}
      pb={2}
    >
      {Object.keys(notificationContacts)
        .filter((key) => !notificationContacts[parseInt(key)].delete)
        .map((key) => {
          const index = parseInt(key);
          return (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <TextField
                name="name"
                label="Nom (facultatif)"
                InputLabelProps={{
                  shrink: true,
                }}
                value={notificationContacts[index].name}
                onChange={(event) => handleChangeNotificationContactsValue(index, event)}
              />
              <TextField
                name="smsOrEmail"
                error={notificationContacts[index].error}
                label="SMS ou courriel"
                InputLabelProps={{
                  shrink: true,
                }}
                value={notificationContacts[index].smsOrEmail}
                onChange={(event) => handleChangeNotificationContactsValue(index, event)}
                sx={{ width: "300px" }}
              />
              {index == 0 && (
                <Tooltip
                  title={"Même numéro que la fiche client (Cellulaire)"}
                  placement="top"
                >
                  <span>
                    <IconButton
                      onClick={handleClickCopyCellPhoneNumber}
                      color="secondary"
                      size="medium"
                      disabled={copyCellphoneNumberDisabled}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {index !== 0 && (
                <Grid
                  item
                  xs={1}
                >
                  <IconButton
                    color="secondary"
                    size="medium"
                    onClick={() => handleClickRemoveNotification(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              )}
            </Stack>
          );
        })}
      <Button
        onClick={handleClickAddNotification}
        color="secondary"
        variant="contained"
        disabled={addNotificationButtonDisabled}
        sx={{ width: "200px" }}
      >
        AJOUTER NOTIFICATION
      </Button>
    </Stack>
  );
};

export default SmsAndEmailNotifications;
