import Button from "@mui/material/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { CircularProgress } from "@mui/material";
import React, { ReactElement } from "react";

type ExportReportToXlsxButtonProps = {
  onClick: () => void;
  isLoading: boolean;
  label: string;
};

const ExportReportToXlsxButton = ({ onClick, isLoading, label }: ExportReportToXlsxButtonProps): ReactElement => {
  return (
    <Button
      sx={{ ml: 2 }}
      startIcon={<InsertDriveFileIcon style={{ color: "#000", marginRight: 5 }} />}
      onClick={onClick}
    >
      <span>{label}</span>
      {isLoading && <CircularProgress style={{ width: 18, height: 18 }} />}
    </Button>
  );
};

export default ExportReportToXlsxButton;
