// React imports
import React, { ReactElement, ReactNode } from "react";
// External imports
import { TableCell, TableRow } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: ReactNode };
};

const headerLabel: HeaderLabel = {
  contractName: { label: "Contrat" },
  customerItemName: { label: "Item de contrat" },
  flow: { label: "Flow" },
  dropPoint: { label: "Point de chute" },
  total: { label: "Quantité" },
};

const header: string[] = ["contractName", "customerItemName", "flow", "dropPoint", "total"];

const FinancialReportTableHeader = (): ReactElement => {
  return (
    <TableRow>
      {header.map((name) => (
        <TableCell key={name}>{headerLabel[name].label}</TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
};

export default FinancialReportTableHeader;
