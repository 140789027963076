import { useReducer, useCallback } from "react";
import { debounce } from "lodash";

const INITIAL_STATE = {
  sort: { columnName: "id", direction: "asc" },
  filter: "",
  rowsPerPage: 10,
  page: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SORT":
      return { ...state, sort: action.payload };
    case "SET_FILTER":
      return { ...state, filter: action.payload };
    case "SET_ROWS_PER_PAGE":
      return { ...state, rowsPerPage: action.payload };
    case "SET_PAGE":
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

function useFilterAndPagination(customInitialState) {

  const initialState = customInitialState ? customInitialState : INITIAL_STATE

  const [state, dispatch] = useReducer(reducer, initialState);

  const setSort = useCallback((columnName, direction) => {
    dispatch({ type: "SET_SORT", payload: { columnName, direction } });
  }, []);

  const setFilter = useCallback(
    debounce((filterValue) => {
      dispatch({ type: "SET_FILTER", payload: filterValue });
    }, 50),
    []
  );

  const setRowsPerPage = useCallback((newRowsPerPage) => {
    dispatch({ type: "SET_ROWS_PER_PAGE", payload: newRowsPerPage });
  }, []);

  const setPage = useCallback((newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage });
  }, []);

  const filterStates = {
    sort: state.sort,
    filter: state.filter,
    rowsPerPage: state.rowsPerPage,
    page: state.page,
  };

  const updateFilterStates = {
    setSort,
    setFilter,
    setRowsPerPage,
    setPage,
  };

  return { filterStates, updateFilterStates };
}

export default useFilterAndPagination;
