import React, { lazy, ReactElement, Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { LoadingSpinner } from "@components/routes/utils";
import withQueryParams from "@context/withQueryParams";

const LocationContainer = lazy(() => import("@components/location/LocationContainer"));

const LocationPage = (): ReactElement => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/"
          element={<Outlet />}
        >
          <Route
            path=""
            element={<LocationContainer />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default withQueryParams(LocationPage);
