import { ReportType } from "@components/report/hooks/report-layout.hook";
import React, { ReactElement } from "react";
import Dropdown from "@ui/dropdown/Dropdown";
import { SelectChangeEvent } from "@mui/material";
import { Customer } from "@@types/customers/customer.type";

type ReportTypeSelectProps = {
  customer: Customer;
  options: ReportType[];
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
};

const ReportTypeSelect = ({ customer, options, value, onChange }: ReportTypeSelectProps): ReactElement => {
  const availableOptions = customer.isFromCiWeb ? options.filter((option) => option.value !== "3") : options;

  const renderMenuItems = (): ReactElement | ReactElement[] => {
    if (availableOptions.length === 0) {
      return <Dropdown.MenuItem value=" "></Dropdown.MenuItem>;
    }
    return availableOptions.map((report) => (
      <Dropdown.MenuItem
        key={report.value}
        value={report.value}
      >
        {report.label}
      </Dropdown.MenuItem>
    ));
  };

  return (
    <Dropdown
      width="150px"
      label={"Types"}
      value={value}
      name="reportType"
      onChange={onChange}
    >
      {renderMenuItems()}
    </Dropdown>
  );
};

export default ReportTypeSelect;
