import React, { ReactElement, ReactNode } from "react";

import * as Sentry from "@sentry/react";
import NoMatch from "@components/NoMatch";

interface SentryErrorBoundaryProps {
  children: ReactNode;
}

const SentryErrorBoundary = ({ children }: SentryErrorBoundaryProps): ReactElement => {
  return <Sentry.ErrorBoundary fallback={<NoMatch />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryErrorBoundary;
