import { useMutation } from "@tanstack/react-query";
import createMasterRoutesReportXlsx from "../../services/reports/create-master-routes-report-xlsx.service";

export type UseCreateMasterRoutesReportXlsx = {
  mutate: () => Promise<string>;
  isPending: boolean;
};

function useCreateMasterRoutesReportXlsx(customerId: string): UseCreateMasterRoutesReportXlsx {
  const mutation = useMutation<string>({
    mutationFn: (): Promise<string> => createMasterRoutesReportXlsx(customerId),
  });

  const mutate = async (): Promise<string> => {
    const response = await mutation.mutateAsync();
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateMasterRoutesReportXlsx;
