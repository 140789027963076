import { MutationResult } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { Report } from "@components/report/hooks/report-layout.hook";
import { MasterRouteReport } from "@@types/reports/master-route-report.type";

const createMasterRoutesReport = async (
  reportSettings: Report,
  customerId: string
): Promise<MutationResult<MasterRouteReport[]>> => {
  const res = await http.post<MutationResult<MasterRouteReport[]>>(`/reports/master_routes_report`, {
    reportSettings,
    customerId,
  });
  return res.data;
};

export default createMasterRoutesReport;
