import { useMutation } from "@tanstack/react-query";
import { Report } from "@components/report/hooks/report-layout.hook";
import createVdqProblemCasesReportXlsx from "../../services/reports/create-vdq-problem-cases-report-xlsx.service";

export type UseCreateVdqProblemCasesReportXlsx = {
  mutate: () => Promise<string>;
  isPending: boolean;
};

function useCreateVdqProblemCasesReportXlsx(
  customerId: string,
  reportSettings: Report
): UseCreateVdqProblemCasesReportXlsx {
  const mutation = useMutation<string>({
    mutationFn: (): Promise<string> => createVdqProblemCasesReportXlsx(reportSettings, customerId),
  });

  const mutate = async (): Promise<string> => {
    const response = await mutation.mutateAsync();
    return response;
  };

  return { mutate, isPending: mutation.isPending };
}

export default useCreateVdqProblemCasesReportXlsx;
