import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
  Select,
  InputLabel,
  Box,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { withCustomerLocations, withJobs, withJobTemplates, withSupplierLocations, withLocations } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";
import styled from "@emotion/styled";

import DatePicker from "./form/DatePickerMui";
import ModalWarning from "./ModalWarning";
import DialogWrapper from "./ui/DialogWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import SelectUi from "./ui/Select";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import { formattedDate } from "@utils/dates";
import TextAreaUi from "./ui/TextArea";
import { filterSupplierLocations } from "@utils/filtering";
import AutocompleteModal from "./ui/AutocompleteModal";
import InlineColorPicker from "./ui/InlineColorPicker";
import { includes } from "lodash";
import ModalInterruption from "@components/modals/interruption/ModalInterruption";
import { formattedPhoneNumber } from "@utils/phoneNumber";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import * as API from "@services";
import { isArrayTruthy } from "@utils/common.js";
import { mapResponsesNotificationContacts } from "@@types/locations/mappers/mapResponseToLocation.mapper";
import SmsAndEmailNotifications from "@components/modals/quickTask/quickJob/SmsAndEmailNotifications";

const ButtonCustom = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.basic.warning};
  }
`;

const DialogActionsSC = styled(DialogActions)`
  && {
    justify-content: space-between;

    button {
      margin: 0 4px;
    }
  }
`;

const initialNotificationContactStates = { id: null, name: "", smsOrEmail: "", error: false, delete: false };

const initialNotificationContactState = { 0: initialNotificationContactStates };

const initialState = {
  errors: {
    kind: false,
    customerDestinationLocationId: false,
    customerLocationId: false,
    startDate: false,
    supplierLocationId: false,
    smsAlertPhoneNumber: false,
  },
  customerLocations: [],
  customerDestinationLocationId: "-1",
  notificationContacts: initialNotificationContactState,
  deleteWarningModalOpened: false,
  isInterruptionModalOpened: false,
  locationModalOpened: false,
  initialJobStateLoaded: false,
  editedStatus: "",
};

class ModalEditJob extends PureComponent {
  state = {
    ...initialState,
    statusOptions: [],
  };

  componentDidMount() {
    this.fetchJobTemplates();
    this.availableStatus();
    this.fetchAllSuppliersLocations();
    this.fetchCustomerLocations();
    // this.fetchDeposits();
    this.setJobInitialState();
  }

  setJobInitialState() {
    console.log(this.props.job);
    this.setState({
      customerLocationId: this.props.job.customerLocationId,
      noteComments: this.props.job.noteComments,
      noteCommentsColor: this.props.job.noteCommentsColor,
      noteLocation: this.props.job.noteLocationToDisplay,
      // noteSchedule: this.props.job.noteSchedule,
      notificationContacts: isArrayTruthy(this.props.job.notificationContacts)
        ? this.props.job.notificationContacts
        : initialNotificationContactState,
      customerMobilePhoneNumber: this.props.job.customerMobilePhoneNumber,
      purchaseOrderNo: this.props.job.purchaseOrderNoToDisplay,
      startDate: this.props.job.startDate,
      jobTemplateId: this.props.job.jobTemplateId,
      code: this.props.job.code,
      kind: this.props.job.kind,
      supplierLocationId: this.props.job.supplierLocationId,
      customerDestinationLocationId: this.props.job.customerDestinationLocationId ?? "-1",
      supplierSecondLocationId: this.props.job.supplierSecondLocationId,
      initialJobStateLoaded: true,
      currentlySelectedCustomerLocationHash: {
        value: this.props.job.customerLocationId,
        label: this.props.job.locationName,
      },
    });
  }

  get valid() {
    const errors = { ...initialState.errors };
    const { customerDestinationLocationId, jobTemplateId, startDate, supplierLocationId, notificationContacts } =
      this.state;
    let valid = true;
    const jobTemplate = this.getJobTemplate(jobTemplateId);
    const jobShouldHaveSupplier =
      jobTemplate.code.indexOf("_VP") > -1 ||
      jobTemplate.code.indexOf("_VR") > -1 ||
      jobTemplate.code.indexOf("_RE") > -1;

    for (const name of ["currentlySelectedCustomerLocationHash", "kind"]) {
      if (this.state[name] === "-1") {
        valid = false;
        errors[name] = true;
      }
    }

    if (jobTemplateId !== "-1") {
      if (jobTemplate.code === "RE" && customerDestinationLocationId === "-1") {
        valid = false;
        errors.customerDestinationLocationId = true;
      }
    }

    if (jobShouldHaveSupplier && (!supplierLocationId || supplierLocationId === "-1")) {
      valid = false;
      errors.supplierLocationId = true;
    }

    if (!startDate) {
      valid = false;
      errors.startDate = true;
    }

    const notificationContactsArray = Object.values(notificationContacts);

    notificationContactsArray.map((contact, index) => {
      const activeNotificationContacts = notificationContactsArray.filter((contact) => !contact.delete);

      if (!contact.delete) {
        const phoneNoLength = contact.smsOrEmail.replace(/\D/g, "").length;

        const smsIsValid = [10, 11].includes(phoneNoLength) && !contact.smsOrEmail.includes("@");

        const emailIsValid = contact.smsOrEmail.includes("@");

        const hasDuplicate = activeNotificationContacts.filter((c) => c.smsOrEmail === contact.smsOrEmail).length > 1;

        if ((!smsIsValid && !emailIsValid) || hasDuplicate) {
          if (activeNotificationContacts.length === 1 && contact.smsOrEmail === "") return;

          this.handleChangeValueNotificationContact("notificationContacts", index, "error", true);

          valid = false;
        }
      }
    });

    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  // eslint-disable-next-line react/sort-comp
  fetchAllSuppliersLocations = () => {
    this.props.fetchAllSuppliersLocations();
  };

  fetchCustomerLocations = async () => {
    const { customerItem } = this.props;
    const customerLocations = await API.Contract.fetchCustomerContractLocations(
      customerItem.customerId,
      customerItem.contractId
    );

    this.setState({
      customerLocations,
    });
    // // eslint-disable-next-line max-len
    // const customerLocation = await this.props.customerLocations.find(
    //   (loc) => loc.id.toString() === customerLocationId.toString()
    // );
    //
    // if (customerLocation) {
    //   const {id, name, note} = customerLocation;
    //
    //   this.setState({
    //     noteLocation: note,
    //     customerLocationId: {value: id, label: name},
    //   });
    // }
  };

  fetchCustomerLocationsAndCloseModal = () => {
    this.setState(
      {
        locationModalOpened: false,
      },
      this.fetchCustomerLocations
    );
  };

  // fetchDeposits = () => {
  //   this.props.fetchDeposits({limit: -1});
  // };

  fetchJobTemplates = async () => {
    const { fetchJobTemplates } = this.props;
    await fetchJobTemplates();
  };

  // eslint-disable-next-line max-len
  getJobTemplate = (jobTemplateId) => this.props.jobTemplates.find(({ id }) => id === jobTemplateId.toString()) || {};

  handleChangeFields = handleChangeFields.bind(this);

  handleChangeStartDate = (date) => {
    this.setState({
      errors: {
        ...this.state.errors,
        startDate: false,
      },
      startDate: formattedDate(date),
    });
  };

  handleChangeCustomerLocation = () => (value) => {
    this.setState({
      errors: {
        customLocationId: false,
      },
      currentlySelectedCustomerLocationHash: value,
    });
    this.changeNoteLocation(value);
    this.changePurchaseOrderNo(value);
    this.ChangeNotificationContacts(value);
  };

  handleChangeJobTemplate = () => (event) => {
    const jobTemplate = this.getJobTemplate(event.target.value);

    this.setState({
      jobTemplateId: jobTemplate.id,
      code: jobTemplate.code,
      kind: jobTemplate.kind,
    });
  };

  changeNoteLocation = (value) => {
    const { customerLocations } = this.state;
    const locationNote = customerLocations.find((customerLocation) => customerLocation.id === value.value);

    this.setState({ noteLocation: locationNote ? locationNote.note : "" });
  };

  changePurchaseOrderNo = (value) => {
    const { customerLocations } = this.state;
    const purchaseOrderNo = customerLocations.find((customerLocation) => customerLocation.id === value.value);

    this.setState({ purchaseOrderNo: purchaseOrderNo ? purchaseOrderNo.purchaseOrderNo : "" });
  };

  ChangeNotificationContacts = (value) => {
    const { customerLocations } = this.state;
    const customerLocation = customerLocations.find((customerLocation) => customerLocation.id === value.value);
    this.setState({
      notificationContacts: isArrayTruthy(customerLocation.notificationContacts)
        ? mapResponsesNotificationContacts(customerLocation.notificationContacts)
        : initialNotificationContactState,
    });
  };

  handleAddAndRemoveNotificationContact = (state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      this.setState((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      this.setState((prevState) => ({ ...prevState, [state]: value }));
    }
  };

  handleChangeValueNotificationContact = (state, key, nestedState, value) => {
    // Since state is an array of object, we want to allow updating of nested properties
    this.setState((prevState) => ({
      ...prevState,
      [state]: {
        ...prevState[state],
        [key]: {
          ...prevState[state][key],
          [nestedState]: value,
        },
      },
    }));
  };

  handleClose = () => {
    this.setState({
      ...initialState,
      ...this.props.job,
    });

    this.props.onClose();
  };

  handleDeleteJob = async () => {
    const { deleteJob, router, job, handleRefund } = this.props;
    const { id, contractId, customerId, customerItemId, paidCustomerItem } = job;

    // If deleting a job that is not refunded yet, process the refund before deleting the job
    if (paidCustomerItem?.refunded !== undefined && !paidCustomerItem?.refunded) {
      await handleRefund.run();
    }

    await deleteJob(id);

    router.navigate(`/customers/${customerId}/contracts/${contractId}/preparations/${customerItemId}`, {
      replace: true,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.valid) {
      const { job, refreshList, updateJob } = this.props;

      const {
        // depositId,
        customerDestinationLocationId,
        currentlySelectedCustomerLocationHash,
        noteComments,
        noteCommentsColor,
        noteLocation,
        // noteSchedule,
        purchaseOrderNo,
        startDate,
        supplierLocationId,
        supplierSecondLocationId,
        editedStatus,
        jobTemplateId,
        code,
        kind,
        notificationContacts,
      } = this.state;

      this.deleteExistingDefaultSmsOrEmailIfNeeded(notificationContacts[0]);

      const { interventionsDispatch } = job;

      await updateJob(job.id, {
        // deposit_id: depositId,
        customer_destination_location_id: customerDestinationLocationId,
        customer_location_id: currentlySelectedCustomerLocationHash.value,
        interventionsDispatch,
        note_comments: noteComments ? noteComments.trim() : "",
        note_comments_color: noteCommentsColor,
        note_location: noteLocation ? noteLocation.trim() : "",
        job_template_id: jobTemplateId,
        code: code,
        kind: kind,
        purchase_order_no: purchaseOrderNo ? purchaseOrderNo.trim() : "",
        notification_contacts: notificationContacts,
        start_date: startDate,
        status: editedStatus,
        supplier_location_id: supplierLocationId,
        supplier_second_location_id: supplierSecondLocationId,
      });

      refreshList();
    }
  };

  deleteExistingDefaultSmsOrEmailIfNeeded = (defaultSmsOrEmail) => {
    const hasDefaultSmsOrEmail = defaultSmsOrEmail.id;
    const defaultValueIsEmpty = defaultSmsOrEmail.smsOrEmail === "";

    if (hasDefaultSmsOrEmail && defaultValueIsEmpty) {
      defaultSmsOrEmail.delete = true;
    }
  };

  handleToggleLocationModal = (opened) => () => {
    this.setState({
      locationModalOpened: opened,
    });
  };

  handleToggleWarningModal = (deleteWarningModalOpened) => () => {
    this.setState({
      deleteWarningModalOpened,
    });
  };

  handleToggleInterruptionModal = (isInterruptionModalOpened) => () => {
    this.setState({
      isInterruptionModalOpened,
    });
  };

  renderJobTemplatesMenuItems = (jobTemplates) => [
    jobTemplates.map(({ kind, id }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {kind}
      </MenuItem>
    )),
  ];

  renderMenuItems = (label, data, key) => [
    <MenuItem
      key="-1"
      value="-1"
    >
      {label}
    </MenuItem>,

    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={id}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderMenuItemsStatus = (data, key) => [
    ...data.map(({ id, ...remainingData }) => (
      <MenuItem
        key={id}
        value={remainingData.status}
        data-cy={remainingData.status}
      >
        {remainingData[key]}
      </MenuItem>
    )),
  ];

  renderLocationsFields = () => {
    const { customerDestinationLocationId, errors, jobTemplateId, supplierLocationId, supplierSecondLocationId } =
      this.state;

    const { customerLocations, jobTemplates } = this.props;

    if (jobTemplates.length === 0) {
      return null;
    }

    const jobTemplate = this.getJobTemplate(jobTemplateId);

    if (jobTemplate.code === "RE") {
      return (
        <Select
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase().indexOf("LI") > -1}
          error={errors.customerDestinationLocationId}
          formControlError={errors.customerDestinationLocationId}
          formHelperErrorMsg={this.getErrorMessage("customerDestinationLocationId")}
          id="cpbr-customer-destination-location"
          inputLabelText={<Trans i18nKey="customer_destination_location" />}
          onChange={this.handleChangeFields("customerDestinationLocationId")}
          value={customerDestinationLocationId ?? ""}
        >
          {this.renderMenuItems(<Trans i18nKey="select_customer_destination_location" />, customerLocations, "name")}
        </Select>
      );
    }

    const supplierLocations = filterSupplierLocations(this.props.supplierLocations, jobTemplate.code);

    return (
      <>
        <Select
          fullWidth
          disabled={
            jobTemplateId === "-1" ||
            jobTemplate.code.toUpperCase().indexOf("LI") > -1 ||
            jobTemplate.code.toUpperCase() === "MECA"
          }
          error={errors.supplierLocationId}
          formControlError={errors.supplierLocationId}
          formHelperErrorMsg={this.getErrorMessage("supplierLocation")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_1" />}
          onChange={this.handleChangeFields("supplierLocationId")}
          value={supplierLocationId ?? ""}
        >
          {this.renderMenuItems(<Trans i18nKey="select_drop_point" />, supplierLocations, "name")}
        </Select>
        <Select
          fullWidth
          disabled={jobTemplateId === "-1" || jobTemplate.code.toUpperCase() !== "ROSD_VP_CC"}
          error={errors.supplierSecondLocationId}
          formControlError={errors.supplierSecondLocationId}
          formHelperErrorMsg={this.getErrorMessage("supplierSecondLocationId")}
          id="cpbr-supplier-location"
          inputLabelText={<Trans i18nKey="drop_point_2" />}
          onChange={this.handleChangeFields("supplierSecondLocationId")}
          value={supplierSecondLocationId ?? ""}
        >
          {this.renderMenuItems(<Trans i18nKey="select_drop_point" />, supplierLocations, "name")}
        </Select>
      </>
    );
  };

  availableStatus = () => {
    const { status } = this.props.job;

    if (status === "IN_PROGRESS") {
      this.setState({
        statusOptions: [
          {
            id: 1,
            name: "À FAIRE",
            status: "IN_PROGRESS_TO_TODO",
          },
          {
            id: 2,
            name: "EN COURS",
            status: "IN_PROGRESS",
          },
          {
            id: 3,
            name: "COMPLETÉ",
            status: "IN_PROGRESS_TO_COMPLETED",
          },
        ],
        editedStatus: status,
      });
    }
    if (status === "TODO") {
      this.setState({
        statusOptions: [
          {
            id: 1,
            name: "À FAIRE",
            status: "TODO",
          },
          {
            id: 2,
            name: "EN COURS",
            status: "TODO_TO_IN_PROGRESS",
          },
          {
            id: 3,
            name: "COMPLÉTÉ",
            status: "TODO_TO_COMPLETED",
          },
        ],
        editedStatus: status,
      });
    }
    if (status === "COMPLETED") {
      this.setState({
        statusOptions: [
          {
            id: 1,
            name: "COMPLETÉ",
            status: "COMPLETED",
          },
          {
            id: 2,
            name: "EN COURS",
            status: "COMPLETED_TO_IN_PROGRESS",
          },
        ],
        editedStatus: status,
      });
    }
  };

  renderStatus = () => {
    const { statusOptions, editedStatus } = this.state;
    if (statusOptions.length === 0) {
      return null;
    }

    return (
      <SelectUi
        data-cy="selectChangeStatusJob"
        disabled={editedStatus === "-1"}
        id="cpbr-job-status"
        inputLabelText={<Trans i18nKey="status.title" />}
        onChange={this.handleChangeFields("editedStatus")}
        value={`${editedStatus}`}
      >
        {this.renderMenuItemsStatus(statusOptions, "name")}
      </SelectUi>
    );
  };

  render() {
    // Si on est as pret a montrer le rendu , on ne le montre pas
    if (
      !this.state.initialJobStateLoaded ||
      this.props.jobTemplatesLoading ||
      this.props.supplierLocationsLoading ||
      this.props.customerLocationsLoading
    ) {
      return null;
    }

    const {
      errors,
      deleteWarningModalOpened,
      isInterruptionModalOpened,
      currentlySelectedCustomerLocationHash,
      locationModalOpened,
      noteComments,
      noteCommentsColor,
      noteLocation,
      // noteSchedule,
      purchaseOrderNo,
      startDate,
      jobTemplateId,
      notificationContacts,
      customerMobilePhoneNumber,
      customerLocations,
    } = this.state;

    const { job, open } = this.props;
    const { contractId, customerId, paidCustomerItem } = job;

    // those are the only flows that can interchange between themselves
    // Flow select only appear for jobs with one of these 3 flows
    const jobTemplates = this.props.jobTemplates.filter(
      (jt) => jt.code === "ROAD_VP" || jt.code === "ROAD_LI" || jt.code === "ROAD_VP_SW"
    );
    const jobTemplatesCodes = jobTemplates.map((jt) => jt.code.includes(job.code));
    const isFlowEditable = includes(jobTemplatesCodes, true);

    return (
      <DialogWrapper
        id="edit-job-modal"
        onClose={this.handleClose}
        open={open}
      >
        <DialogTitle>
          <Trans i18nKey="edit_job" />
        </DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>{this.renderStatus()}</FormGroupWrapper>

            <AutocompleteModal
              actionName="add"
              displayHelperButton
              autocompleteList={customerLocations}
              autocompletePlaceholder="Saisir l'emplacement"
              autocompleteValue={currentlySelectedCustomerLocationHash}
              buttonClass="cta-add-location"
              buttonTextId="preparation.add_location_button"
              contractId={contractId}
              customerId={customerId}
              errors={errors}
              errorMessagesHelper={this.getErrorMessage("currentlySelectedCustomerLocationHash")}
              modalCallback={this.fetchCustomerLocationsAndCloseModal}
              modalName="customerLocation"
              modalOpened={locationModalOpened}
              onChangeAutocomplete={this.handleChangeCustomerLocation(currentlySelectedCustomerLocationHash)}
              onClickButton={this.handleToggleLocationModal(true)}
              onCloseModal={this.handleToggleLocationModal(false)}
              dropDownMenuPortalTarget="#edit-job-modal"
            />

            <FormGroupWrapper>
              <FormControl error={errors.startDate}>
                <DatePicker
                  error={errors.startDate}
                  label="Date"
                  value={moment(startDate)}
                  onChange={this.handleChangeStartDate}
                  variant="outlined"
                  disablePast={false}
                />

                <FormHelperText>{this.getErrorMessage("startDate")}</FormHelperText>
              </FormControl>
            </FormGroupWrapper>

            {isFlowEditable && (
              <FormGroupWrapper>
                <FormControl>
                  <InputLabel shrink>Flow</InputLabel>
                  <Select
                    notched
                    label="Flow"
                    value={jobTemplateId}
                    onChange={this.handleChangeJobTemplate()}
                    variant="outlined"
                  >
                    {this.renderJobTemplatesMenuItems(jobTemplates)}
                  </Select>
                </FormControl>
              </FormGroupWrapper>
            )}

            {/* {this.renderDepositField()} */}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              pb={3}
            >
              {this.renderLocationsFields()}
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              pb={3}
            >
              <TextField
                fullWidth
                error={errors.purchaseOrderNo}
                helperText={this.getErrorMessage("purchaseOrderNo")}
                label={<Trans i18nKey="purchase_order_no" />}
                onChange={this.handleChangeFields("purchaseOrderNo")}
                value={purchaseOrderNo || ""}
              />
            </Stack>

            {/* EMAIL AND SMS PHONE NUMBER TEXTFIELD */}
            <Stack spacing={1}>
              <Typography variant="body1">Notifications SMS ou courriel</Typography>
              <SmsAndEmailNotifications
                notificationContacts={notificationContacts}
                customerMobilePhoneNumber={customerMobilePhoneNumber}
                addNotificationButtonDisabled={
                  notificationContacts[0].smsOrEmail === "" ||
                  Object.values(notificationContacts).filter((contact) => !contact.delete).length === 4
                }
                copyCellphoneNumberDisabled={notificationContacts[0].smsOrEmail || !customerMobilePhoneNumber}
                setSingleCurrent={this.handleAddAndRemoveNotificationContact}
                setSingleNestedCurrent={this.handleChangeValueNotificationContact}
              />
            </Stack>

            <FormGroupWrapper>
              <TextAreaUi
                error={errors.note_comments}
                helperText={this.getErrorMessage("note_comments")}
                id="cpbr-note-comments"
                label={<Trans i18nKey="preparation.note_comments" />}
                onChange={this.handleChangeFields("noteComments")}
                value={noteComments || ""}
                data-cy="noteJobEditJob"
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextAreaUi
                error={errors.note_location}
                helperText={this.getErrorMessage("note_location")}
                id="cpbr-note-location"
                label={<Trans i18nKey="preparation.note_location" />}
                onChange={this.handleChangeFields("noteLocation")}
                value={noteLocation || ""}
                data-cy="noteLocationEditJob"
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <InlineColorPicker
                color={noteCommentsColor || "#000"}
                id="cpbr-note-comments-color"
                onChangeComplete={({ hex }) => {
                  this.setState({ noteCommentsColor: hex });
                }}
              />
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActionsSC>
          {!job.intervention.is_interrupted && (
            <ButtonCustom onClick={this.handleToggleWarningModal(true)}>
              <Trans i18nKey="delete_job" />
            </ButtonCustom>
          )}

          <Box textAlign="left">
            {!job.intervention.is_interrupted && (
              <ButtonCustom onClick={this.handleToggleInterruptionModal(true)}>INTERRUPTION</ButtonCustom>
            )}
          </Box>

          <span>
            <Button onClick={this.handleClose}>
              <Trans i18nKey="cancel" />
            </Button>

            <Button
              onClick={this.handleSubmit}
              variant="contained"
              data-cy="buttonConfirmEditJob"
            >
              <Trans i18nKey="edit" />
            </Button>
          </span>
        </DialogActionsSC>

        {isInterruptionModalOpened && (
          <ModalInterruption
            onCancel={this.handleToggleInterruptionModal(false)}
            open={isInterruptionModalOpened}
            handleClose={this.handleClose}
            refreshList={this.props.refreshList}
            job={job}
          />
        )}

        <ModalWarning
          onCancel={this.handleToggleWarningModal(false)}
          onSubmit={this.handleDeleteJob}
          open={deleteWarningModalOpened}
          title={<Trans i18nKey="warning" />}
        >
          {paidCustomerItem?.refunded !== undefined && !paidCustomerItem?.refunded ? (
            <Typography>
              Vous allez rembourser la somme de{" "}
              {(paidCustomerItem?.refundableAmount / 100).toFixed(2) || "somme introuvable"}$ en supprimant cette tâche,
              êtes-vous sûr?
            </Typography>
          ) : (
            <Trans i18nKey="warning_delete_job" />
          )}
        </ModalWarning>
      </DialogWrapper>
    );
  }
}

ModalEditJob.propTypes = {
  customerItem: PropTypes.object.isRequired,
  customerLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteJob: PropTypes.func.isRequired,
  fetchAllSuppliersLocations: PropTypes.func.isRequired,
  fetchCustomerLocations: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  jobTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  updateJob: PropTypes.func.isRequired,
};

export default withRouter(
  withJobs(withJobTemplates(withCustomerLocations(withLocations(withSupplierLocations(ModalEditJob)))))
);
