import React, { ReactElement } from "react";
import { Paper, Table, TableBody, TableContainer, TableHead } from "@mui/material";
import { MasterRouteReport } from "@@types/reports/master-route-report.type";
import MasterRoutesReportTableHeader from "@components/report/master-routes-report-table/MasterRoutesReportTableHeader";
import MasterRoutesReportTableBody from "@components/report/master-routes-report-table/MasterRoutesReportTableBody";
import useMasterRoutesReportTable from "@components/report/hooks/master-routes-report-table.hook";
import ExportReportToXlsxButton from "@components/report/buttons/ExportReportToXlsxButton";
import { Customer } from "@@types/customers/customer.type";

type MasterRoutesReportTableProps = {
  customer: Customer;
  report: MasterRouteReport[];
  isLoading: boolean;
};

const MasterRoutesReportTable = ({ customer, report, isLoading }: MasterRoutesReportTableProps): ReactElement => {
  const { handleClickExportMasterRoutesReportToXlsx, xlsxFileIsPending } = useMasterRoutesReportTable(
    customer.id.toString()
  );
  return (
    <TableContainer component={Paper}>
      {!isLoading && (
        <ExportReportToXlsxButton
          onClick={handleClickExportMasterRoutesReportToXlsx}
          isLoading={xlsxFileIsPending}
          label={"Télécharger rapport routes maître"}
        />
      )}
      <Table aria-label="collapsible table">
        <TableHead>
          <MasterRoutesReportTableHeader />
        </TableHead>
        <TableBody>
          <MasterRoutesReportTableBody
            report={report}
            isLoading={isLoading}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterRoutesReportTable;
