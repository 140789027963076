import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const ButtonWrapper = styled(Button)`
  width: 100%;

  @media screen and (min-width: 575px) {
    width: initial;
  }
`;

export const LoadingSpinner = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
