import { Report } from "@components/report/hooks/report-layout.hook";
import useNotifier from "@hooks/useNotifier";
import useCreateProblemCasesReportXlsx from "@hooks/reports/create-problem-cases-report-xlsx.hook";
import useCreateProblemCasesReportPhotos from "@hooks/reports/create-problem-cases-report-photos.hook";
import useCreateVdqProblemCasesReportXlsx from "@hooks/reports/create-vdq-problem-cases-report-xlsx.hook";
import { downloadFile } from "@utils/files";
import { formattedTimeForExport } from "@utils/dates";

type UseProblemCasesReportTable = {
  xlsxFileIsPending: boolean;
  vdqXlsxFileIsPending: boolean;
  photosIsPending: boolean;
  handleClickExportProblemCasesReportToXlsx: () => void;
  handleClickExportVdqProblemCasesReportToXlsx: () => void;
  handleClickExportProblemCasesReportPhotos: () => void;
};

const useProblemCasesReportTable = (reportSettings: Report, customerId: string): UseProblemCasesReportTable => {
  const { mutate: exportXlsxFile, isPending: xlsxFileIsPending } = useCreateProblemCasesReportXlsx(
    customerId,
    reportSettings
  );
  const { mutate: exportVdqXlsxFile, isPending: vdqXlsxFileIsPending } = useCreateVdqProblemCasesReportXlsx(
    customerId,
    reportSettings
  );
  const { mutate: exportPhotos, isPending: photosIsPending } = useCreateProblemCasesReportPhotos(
    customerId,
    reportSettings
  );
  const { enqueueMessage } = useNotifier();

  const handleClickExportProblemCasesReportToXlsx = (): void => {
    exportXlsxFile().then((response) => {
      if (response) {
        downloadFile(
          new Blob([response]),
          `rapport_cas_problemes_du_${reportSettings.startDate}_au_${reportSettings.endDate}.xlsx`
        );
        enqueueMessage("Fichier .xslx de rapport des cas problèmes téléchargé avec succès.", "success");
      } else {
        enqueueMessage(
          "Une erreur est survenue lors du téléchargement du fichier .xlsx de rapport de cas problèmes.",
          "error"
        );
      }
    });
  };

  const handleClickExportVdqProblemCasesReportToXlsx = (): void => {
    exportVdqXlsxFile().then((response) => {
      if (response) {
        downloadFile(
          new Blob([response]),
          `rapport_cas_problemes_du_${reportSettings.startDate}_au_${reportSettings.endDate}_vdq.xlsx`
        );
        enqueueMessage("Fichier .xlsx de rapport de cas problèmes VDQ téléchargé avec succès.", "success");
      } else {
        enqueueMessage(
          "Une erreur est survenue lors du téléchargement du fichier .xlsx de rapport de cas problèmes VDQ.",
          "error"
        );
      }
    });
  };

  const handleClickExportProblemCasesReportPhotos = (): void => {
    exportPhotos().then((response) => {
      if (response) {
        downloadFile(new Blob([response]), `${formattedTimeForExport()}.zip`);
        enqueueMessage("Photos de cas problèmes téléchargés avec succès.", "success");
      } else {
        enqueueMessage("Une erreur est survenue lors du téléchargement des photos.", "error");
      }
    });
  };

  return {
    xlsxFileIsPending,
    vdqXlsxFileIsPending,
    photosIsPending,
    handleClickExportProblemCasesReportToXlsx,
    handleClickExportVdqProblemCasesReportToXlsx,
    handleClickExportProblemCasesReportPhotos,
  };
};

export default useProblemCasesReportTable;
