import { AxiosResponse } from "axios";
import { AdministrativeSectorsResponse } from "@@types/administrative-sectors/administrative-sectors-response.type";
import { axiosGeoApi } from "../axios";
import { ADMINISTRATIVE_SECTORS_API_ROUTES } from "./constants";

export class AdministrativeSectorsService {
  static async fetchAdministrativeSectors(): Promise<AxiosResponse<AdministrativeSectorsResponse>> {
    return axiosGeoApi.get<AdministrativeSectorsResponse>(ADMINISTRATIVE_SECTORS_API_ROUTES.list);
  }
}
