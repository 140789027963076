import React, { createContext, ReactElement, useEffect, useMemo } from "react";
import { MatterCodes, MatterListCodes } from "@@types/matter/matter";
import useFetchListCodes from "@hooks/list-codes/fetch-list-codes.hook";
import ListCode from "@@types/list-codes/list-code.type";

type ContextState = {
  matterListCodes: MatterListCodes;
};

export const ListCodesContext = createContext({} as ContextState);

type Props = {
  children: ReactElement;
};

function ListCodesProvider({ children }: Props): ReactElement {
  const { data: matterListCodesData, isLoading: matterListCodeLoading } = useFetchListCodes("CONTAINER_MATTER");
  const [matterListCodes, setMatterListCodes] = React.useState<MatterListCodes>({} as MatterListCodes);

  useEffect(() => {
    if (matterListCodesData && !matterListCodeLoading) {
      const newMatterListCodes = matterListCodesData.reduce(
        (acc: MatterListCodes, matter: ListCode): MatterListCodes => {
          if (matter.codeType === "CONTAINER_MATTER") {
            acc[matter.code as MatterCodes] = {
              code: matter.code as MatterCodes,
              codeType: "CONTAINER_MATTER",
              sequence: matter.sequence,
              display: matter.display,
              id: matter.id,
              description: matter.description,
            };
          }

          return acc;
        },
        {} as MatterListCodes
      );

      setMatterListCodes(newMatterListCodes);
    }
  }, [matterListCodesData, matterListCodeLoading]);

  const value: ContextState = useMemo(
    () => ({
      matterListCodes: matterListCodes,
    }),
    [matterListCodes]
  );

  return <ListCodesContext.Provider value={value}>{children}</ListCodesContext.Provider>;
}

function useListCodesContext(): ContextState {
  const context = React.useContext(ListCodesContext);
  if (context === undefined) {
    throw new Error("useListCodesContext must be used within a ListCodesProvider");
  }
  return context as unknown as ContextState;
}

/**
 * Children component
 * @param Component
 */
function withListCodesContext<T extends React.JSX.IntrinsicAttributes = React.JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>
): React.ComponentType<T> {
  return function fn(props: T) {
    return (
      <ListCodesProvider>
        <Component {...props} />
      </ListCodesProvider>
    );
  };
}

export { ListCodesProvider, withListCodesContext, useListCodesContext };
