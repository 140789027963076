import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 as http } from "../axios";
import { Customer } from "@@types/customers/customer.type";

const fetchShowCustomer = async (customerId: string): Promise<ApiResponse<Customer>> => {
  try {
    const res = await http.get<ApiResponse<Customer>>(`/customers/${customerId}`);
    return res.data;
  } catch (error) {
    throw error as Error;
  }
};

export default fetchShowCustomer;
