import { ApiResponse } from "@@types/apiResponse";
import { axiosClientV2 } from "../axios";
import { FEATURE_FLAGS_ROUTES } from "./constants";
import { AxiosResponse } from "axios";
import { FeatureFlagResponse } from "@@types/feature-flags/feature-flag.type";

class FeatureFlagsService {
  static async fetchList(): Promise<AxiosResponse<ApiResponse<FeatureFlagResponse[]>>> {
    return axiosClientV2.get<ApiResponse<FeatureFlagResponse[]>>(
      FEATURE_FLAGS_ROUTES.featureFlags,
    );
  }
}

export default FeatureFlagsService;
