import { GeoSectorDetail, SectorDetail, SectorDetails } from "@@types/sector-details/sector-details.type";

export function mapResponseToSectorDetail(response: GeoSectorDetail): SectorDetail {
  return {
    ...response,
  };
}

export function mapResponsesToSectorDetails(responses: GeoSectorDetail[]): SectorDetails {
  return responses.reduce((acc: SectorDetails, response: GeoSectorDetail): SectorDetails => {
    acc[response.id] = mapResponseToSectorDetail(response);
    return acc;
  }, {} as SectorDetails);
}
