import useNotifier from "@hooks/useNotifier";
import useCreateMasterRoutesReportXlsx from "@hooks/reports/create-master-routes-report-xlsx.hook";
import { downloadFile } from "@utils/files";

type UseMasterRoutesReportTable = {
  xlsxFileIsPending: boolean;
  handleClickExportMasterRoutesReportToXlsx: () => void;
};

const useMasterRoutesReportTable = (customerId: string): UseMasterRoutesReportTable => {
  const { mutate: exportXlsxFile, isPending: xlsxFileIsPending } = useCreateMasterRoutesReportXlsx(customerId);
  const { enqueueMessage } = useNotifier();

  const handleClickExportMasterRoutesReportToXlsx = (): void => {
    exportXlsxFile().then((response) => {
      if (response) {
        downloadFile(new Blob([response]), `rapport_route_maître.xlsx`);
        enqueueMessage("Fichier .xlsx de rapport de routes maître téléchargé avec succès.", "success");
      } else {
        enqueueMessage(
          "Une erreur est survenue lors du téléchargement du fichier .xlsx de rapport de routes maître.",
          "error"
        );
      }
    });
  };

  return {
    xlsxFileIsPending,
    handleClickExportMasterRoutesReportToXlsx,
  };
};

export default useMasterRoutesReportTable;
