import React, { ReactElement } from "react";
import useReportLayout from "@components/report/hooks/report-layout.hook";
import PageContainer from "@ui/PageContainer";
import PaperWrapper from "@ui/PaperWrapper";
import TableOverflowWrapper from "@ui/TableOverflowWrapper";
import { Stack } from "@mui/material";
import ReportDateSelector from "@components/report/ReportDateSelector";
import ReportTypeSelect from "@components/report/dropdowns/ReportTypeSelect";
import ReportContractsSelect from "@components/report/dropdowns/ReportContractsSelect";
import ReportCreateButton from "@components/report/ReportCreateButton";
import FinancialReportTable from "@components/report/financial-report-table/FinancialReportTable";
import { LoadingSpinner } from "@components/routes/utils";
import ProblemCasesReportTable from "@components/report/problem-cases-report-table/ProblemCasesReportTable";
import MasterRoutesReportTable from "@components/report/master-routes-report-table/MasterRoutesReportTable";
import { Customer } from "@@types/customers/customer.type";

type ReportLayoutProps = {
  customer: Customer;
};

const ReportLayout = ({ customer }: ReportLayoutProps): ReactElement => {
  const {
    reportTypes,
    report,
    handleChangeSelectedReportType,
    handleChangeSelectedContracts,
    handleChangeStartDate,
    handleChangeEndDate,
    handleClickCreateReport,
    selectAllContracts,
    financialReport,
    problemCasesReport,
    masterRoutesReport,
    isLoading,
  } = useReportLayout(customer);

  return (
    <PageContainer style={{ width: "100%" }}>
      <PaperWrapper>
        <TableOverflowWrapper>
          <Stack direction="row">
            <Stack
              direction="row"
              p={2}
              spacing={2}
            >
              <ReportTypeSelect
                customer={customer}
                options={reportTypes}
                value={report.reportType}
                onChange={handleChangeSelectedReportType}
              />

              <ReportContractsSelect
                selectAllContracts={selectAllContracts}
                selectedContracts={report.contractIds}
                contracts={customer.contracts}
                onChange={handleChangeSelectedContracts}
                selectedReportType={report.reportType}
              />

              {report.reportType != "3" && (
                <ReportDateSelector
                  onChangeStartDate={handleChangeStartDate}
                  onChangeEndDate={handleChangeEndDate}
                  startDate={report.startDate}
                  endDate={report.endDate}
                />
              )}
            </Stack>

            <ReportCreateButton
              onClick={handleClickCreateReport}
              isLoading={isLoading}
            />
          </Stack>

          {isLoading && <LoadingSpinner />}

          {!isLoading && financialReport && report.reportType == "1" && (
            <FinancialReportTable
              customer={customer}
              report={financialReport}
              reportSettings={report}
              isLoading={isLoading}
            />
          )}

          {!isLoading && problemCasesReport && report.reportType == "2" && (
            <ProblemCasesReportTable
              customer={customer}
              report={problemCasesReport}
              reportSettings={report}
              isLoading={isLoading}
            />
          )}

          {!isLoading && masterRoutesReport && report.reportType == "3" && (
            <MasterRoutesReportTable
              customer={customer}
              report={masterRoutesReport}
              isLoading={isLoading}
            />
          )}
        </TableOverflowWrapper>
      </PaperWrapper>
    </PageContainer>
  );
};

export default ReportLayout;
