// React imports
import React, { ReactElement, ReactNode } from "react";
// External imports
import { TableCell, TableRow } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: ReactNode };
};

const headerLabel: HeaderLabel = {
  fileNumber: { label: "No. Dossier" },
  companyName: { label: "Commerce" },
  doorNo: { label: "No. civique" },
  street: { label: "Rue" },
  city: { label: "Ville" },
  postalCode: { label: "Code postal" },
  dechets: { label: "Déchets" },
  recyclage: { label: "Recyclage" },
};

const header: string[] = [
  "fileNumber",
  "companyName",
  "doorNo",
  "street",
  "city",
  "postalCode",
  "dechets",
  "recyclage",
];

const MasterRoutesReportTableHeader = (): ReactElement => {
  return (
    <TableRow>
      {header.map((name) => (
        <TableCell key={name}>{headerLabel[name].label}</TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
};

export default MasterRoutesReportTableHeader;
