import { GeoSector, Sector, Sectors } from "@@types/sectors/sectors.type";

export function mapResponseToSector(response: GeoSector): Sector {
  return {
    ...response,
  };
}

export function mapResponsesToSectors(responses: GeoSector[]): Sectors {
  return responses.reduce((acc: Sectors, response: GeoSector): Sectors => {
    acc[response.id] = mapResponseToSector(response);
    return acc;
  }, {} as Sectors);
}
