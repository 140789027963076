import { CustomerResponse } from "@@types/customers/customer-response.type";
import { Customer } from "@@types/customers/customer.type";

function mapResponseToCustomer(response: CustomerResponse | undefined): Customer | undefined {
  if (response == undefined) return undefined;
  return {
    id: response.id,
    name: response.name,
    contracts: response.contracts,
    isFromCiWeb: response.isFromCiWeb,
  };
}

export default mapResponseToCustomer;
