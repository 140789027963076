import axios from "axios";
import { requestOptions } from "@services/auth";

const fetchContractRoutes = async () => {
  try {
    const res = await axios.get(`${import.meta.env.VITE_API_URL}/contract_routes`, requestOptions());

    return res.data?.data?.data;
  } catch (error) {
    console.warn("[API.contractRoutesService.fetchContractRoutes] error:", error);
    return error.response;
  }
};

export { fetchContractRoutes };
