import React, { ReactElement } from "react";
import ReportLayout from "@components/report/ReportLayout";
import useReportContainer from "@components/report/hooks/report-container.hook";
import { LoadingSpinner } from "@components/routes/utils";
import { CircularProgress } from "@mui/material";

const ReportContainer = (): ReactElement => {
  const { customer, isPending } = useReportContainer();

  if (isPending) {
    return (
      <LoadingSpinner>
        <CircularProgress
          color="primary"
          size={70}
        />
      </LoadingSpinner>
    );
  }

  return <ReportLayout customer={customer} />;
};

export default ReportContainer;
