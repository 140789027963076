import { QueryKey } from "@tanstack/react-query";

type AdministrativeSectorsKeys = {
  base: "administrative-sectors";
  list: () => QueryKey;
};

export const administrativeSectorsKeys: AdministrativeSectorsKeys = {
  base: "administrative-sectors",
  list: () => [administrativeSectorsKeys.base, "list"],
};
