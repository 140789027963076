import React, { ReactElement } from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Trans } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FinancialReportData } from "@@types/reports/financial-report.type";
import { isArrayTruthy } from "@utils/common";

type FinancialReportParentRowProps = {
  key: string;
  row: FinancialReportData;
  collapseRow: boolean;
  onClick: () => void;
};

const FinancialReportParentRow = ({ key, row, onClick, collapseRow }: FinancialReportParentRowProps): ReactElement => {
  return (
    <TableRow
      key={key}
      sx={{ "&:hover": { backgroundColor: "#e7f8ff" }, cursor: "pointer" }}
      onClick={onClick}
    >
      <TableCell>{row.contractName}</TableCell>
      <TableCell>
        <span style={{ fontWeight: "bold" }}>
          <Trans i18nKey={`report.statuses.${row.type}`} />
        </span>{" "}
        - {row.customerItemName}
      </TableCell>
      <TableCell>{row.flow}</TableCell>
      <TableCell>{row.dropPoint}</TableCell>
      <TableCell align="center">{row.total}</TableCell>
      <TableCell sx={{ paddingRight: 3 }}>
        {isArrayTruthy(row.datesWithEventsCount) && (
          <IconButton key={key}>{collapseRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default FinancialReportParentRow;
