import React, { createContext, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { AdministrativeSectorDetails } from "@@types/administrative-sectors/administrative-sector.type";
import useFetchAdministrativeSectors from "@hooks/administrative-sectors/useFetchAdministrativeSectors.hook";

export type AdministrativeSectorsContextState = {
  administrativeSectors: AdministrativeSectorDetails;
  setAdministrativeSectors: Dispatch<SetStateAction<AdministrativeSectorDetails>>;
  isLoading: boolean;
};

export const AdministrativeSectorsContext = createContext({} as AdministrativeSectorsContextState);

type Props = {
  children: ReactElement;
};

function AdministrativeSectorsProvider({ children }: Props): ReactElement {
  const { data, isLoading, isPending } = useFetchAdministrativeSectors();

  const [administrativeSectors, setAdministrativeSectors] = useState<AdministrativeSectorDetails>({});

  useEffect(() => {
    if (data && !isLoading && !isPending) {
      setAdministrativeSectors(data);
    }
  }, [data, isLoading, isPending]);

  const value: AdministrativeSectorsContextState = useMemo(
    () => ({
      administrativeSectors,
      setAdministrativeSectors,
      isLoading: isLoading || isPending,
    }),
    [isLoading, isPending, administrativeSectors]
  );

  return <AdministrativeSectorsContext.Provider value={value}>{children}</AdministrativeSectorsContext.Provider>;
}

function useAdministrativeSectorsContext(): AdministrativeSectorsContextState {
  const context = React.useContext(AdministrativeSectorsContext);
  if (context === undefined) {
    throw new Error("useAdministrativeSectorsContext must be used within a AdministrativeSectorsProvider");
  }
  return context as unknown as AdministrativeSectorsContextState;
}

/**
 * Children component
 * @param Component
 */
function withAdministrativeSectorsContext<T extends object>(Component: React.ComponentType<T>): React.ComponentType<T> {
  return function fn(props: T) {
    return (
      <AdministrativeSectorsProvider>
        <Component {...props} />
      </AdministrativeSectorsProvider>
    );
  };
}

export { AdministrativeSectorsProvider, withAdministrativeSectorsContext, useAdministrativeSectorsContext };
