// React imports
import { useState, useEffect, useMemo, useCallback } from "react";

// Internal imports
import * as API from "@services";
import useAsync from "@hooks/useAsync";
import useNotifier from "@hooks/useNotifier";
import useApiResponseHandler from "@hooks/useApiResponseHandler";

// External importrs
import moment from "moment";
import { map, orderBy, find, maxBy } from "lodash";
import { isArrayTruthy } from "@utils/common.js";
import { mapResponsesNotificationContacts } from "@@types/locations/mappers/mapResponseToLocation.mapper";

moment.locale("fr-ca");
const today = moment().format("L");

const initialErrorState = {
  customer: false,
  contract: false,
  location: false,
  customerItem: false,
  flow: false,
  dropPointPrimary: false,
  destination: false,
  locationAreaCustomFee: false,
  destinationAreaCustomFee: false,
  notificationContacts: false,
};

const initialCurrentState = { value: null, label: "", data: null };

const initialNotificationContactStates = { id: null, name: "", smsOrEmail: "", error: false, delete: false };

const initialNotificationContactState = { 0: initialNotificationContactStates };

const initialCurrentStates = {
  notificationContacts: initialNotificationContactState,
  customer: initialCurrentState,
  contract: initialCurrentState,
  location: initialCurrentState,
  customerItem: initialCurrentState,
  flow: initialCurrentState,
  date: today,
  unit: initialCurrentState,
  dropPointPrimary: initialCurrentState,
  dropPointSecondary: initialCurrentState,
  destination: initialCurrentState,
  jobNote: null,
  jobNoteColor: "#000",
  locationNote: null,
  purchaseOrderNo: null,
  lastCustomerItemName: null,
  locationAreaCustomFee: null,
  destinationAreaCustomFee: null,
  repeat: 1,
  price: null,
  locationCustomAreaCode: null,
  destinationCustomAreaCode: null,
  paymentMethod: null,
  customerMobilePhoneNumber: null,
  addNotificationButtonDisabled: true,
};

const initialDataState = { data: null, loading: false };
const initialDataStates = {
  customers: initialDataState,
  contracts: initialDataState,
  locations: initialDataState,
  customerItems: initialDataState,
  units: initialDataState,
  suppliers: initialDataState,
  destinations: initialDataState,
};

const initialStripeState = { paymentMethods: [], isPaymentMethodInvalid: false };

const fetchCustomerJobContractsAsync = async (customerID, setSingleData) => {
  const contracts = await API.Contract.fetchCustomerJobContracts(customerID);

  // Format each contract's customerItems and each customerItem's job_templates
  const formattedContractCustomerItems =
    contracts &&
    map(contracts, (contract) => {
      contract.customerItems &&
        map(contract.customerItems, (customerItem) => {
          const formattedJobTemplates = formattedSelect(customerItem.job_templates, "id", "kind");
          customerItem.job_templates = { data: formattedJobTemplates, loading: false };
        });

      const formattedCustomerItems = formattedSelect(contract.customerItems, "id", "name");
      contract.customerItems = { data: formattedCustomerItems, loading: false };

      return contract;
    });

  // Format the accounting-items
  const formattedContracts = formattedSelect(formattedContractCustomerItems, "id", "name");

  contracts && setSingleData("contracts", formattedContracts, "data");
};

const fetchJobComputedPriceAsync = async (
  contract,
  customer,
  customerItemId,
  jobTemplateId,
  customerLocationId,
  customerDestinationId,
  locationAreaCustomFee,
  destinationAreaCustomFee,
  setSingleCurrent,
  handleApiResponse,
  locationCustomAreaCode,
  destinationCustomAreaCode
) => {
  if (contract.paymentTypeCode !== "CREDIT_CARD" || !customer.stripeCustomerId) {
    return;
  }

  const res = await API.CustomerItem.fetchJobComputedPrice(
    customerItemId,
    jobTemplateId,
    customerLocationId,
    customerDestinationId,
    locationAreaCustomFee,
    destinationAreaCustomFee,
    locationCustomAreaCode,
    destinationCustomAreaCode
  );

  handleApiResponse(res, () => {
    setSingleCurrent("price", res.data);
  });
};

const fetchCustomerContractLocationsAsync = async (customerID, contractID, setData) => {
  const locations = await API.Contract.fetchCustomerContractLocations(customerID, contractID);
  const formattedLocations = formattedSelect(locations, "id", "name");

  // Destinations are based off the customer contract locations, but for consitency keep in different arrays
  locations &&
    setData((prevState) => ({
      ...prevState,
      locations: { ...prevState.locations, data: formattedLocations },
      destinations: { ...prevState.destinations, data: formattedLocations },
    }));
};

const fetchLastCustomerItemAsync = async (contractID, locationID, setSingleCurrent) => {
  const customerItem = await API.CustomerItem.fetchLastCustomerItem(contractID, locationID);
  customerItem && setSingleCurrent("lastCustomerItemName", customerItem);
};

const findDefaultOrLatestPaymentMethod = (customer, paymentMethods) => {
  const defaultPaymentMethodId = customer?.stripeDefaultPaymentMethodId;
  const existingDefaultPaymentMethod = find(paymentMethods, ["id", defaultPaymentMethodId]);
  const latestPaymentMethod = maxBy(paymentMethods, (paymentMethod) => paymentMethod.created);

  return existingDefaultPaymentMethod || latestPaymentMethod;
};

const fetchAndValidateStripeCustomerPaymentMethodAsync = async (
  customer,
  contract,
  setSingleStripe,
  setSingleCurrent,
  notifier
) => {
  if (contract && contract.paymentTypeCode !== "CREDIT_CARD") {
    return;
  }

  if (!customer.stripeCustomerId) {
    setSingleStripe("isPaymentMethodInvalid", true);
    return notifier.enqueueMessage(
      "Afin d'attribuer une tâche, vous devez associer une carte de crédit valide au client."
    );
  }

  const stripePaymentMethods = await API.Stripe.customerCreditCardsValidation(customer.stripeCustomerId);

  if (stripePaymentMethods.status === "success") {
    // If the customer has a saved defaultPaymentMethod already, find the existing stripe payment method
    // linked to it. If it does not exist, fall back to the latest created payment method from the Stripe
    // dashboard. If there is no default payment method, just use the latest one from Stripe.
    const defaultPaymentMethod = findDefaultOrLatestPaymentMethod(customer, stripePaymentMethods.data);

    setSingleStripe("paymentMethods", stripePaymentMethods.data);
    !!defaultPaymentMethod && setSingleCurrent("paymentMethod", defaultPaymentMethod);
  } else {
    notifier.enqueueMessages(stripePaymentMethods.messages);

    setSingleStripe("isPaymentMethodInvalid", true);
  }
};

const formattedSelect = (elements, value = "value", label = "label") => {
  // console.log("[formattedSelect] elements:", elements);
  if (!elements || elements.length <= 0) {
    return [];
  }

  const formattedElements = map(elements, (element) => ({
    value: element[value],
    label: element[label],
    data: element,
  }));

  const orderedElements = orderBy(formattedElements, ["label"], ["asc"]);

  return orderedElements;
};

export default function useQuickJob(initialUnits = null) {
  // console.log("[useQuickJob] >>> RENDERED");
  const notifier = useNotifier();
  const handleApiResponse = useApiResponseHandler();
  const formattedUnits = useMemo(() => formattedSelect(initialUnits), [initialUnits]);
  const [errors, setErrors] = useState(initialErrorState);
  const [current, setCurrent] = useState(initialCurrentStates);
  const [data, setData] = useState({ ...initialDataStates, units: { data: formattedUnits, loading: false } });
  const [stripe, setStripe] = useState(initialStripeState);

  const {
    customer,
    contract,
    location,
    customerItem,
    flow,
    date,
    unit,
    dropPointPrimary,
    dropPointSecondary,
    destination,
    jobNote,
    jobNoteColor,
    locationNote,
    purchaseOrderNo,
    lastCustomerItemName,
    locationAreaCustomFee,
    destinationAreaCustomFee,
    repeat,
    locationCustomAreaCode,
    destinationCustomAreaCode,
    paymentMethod,
    customerMobilePhoneNumber,
    notificationContacts,
  } = current;

  const { customers, contracts, locations, customerItems, units, suppliers, destinations } = data;

  const fetchInitialData = useAsync(async () => {
    const customers = await API.Customer.fetchCustomersWithJob();
    const suppliers = await API.Location.fetchSuppliers();

    // Update the state only once with both
    setData((prevState) => ({
      ...prevState,
      customers: { ...prevState.customers, data: formattedSelect(customers, "id", "name") },
      suppliers: { ...prevState.suppliers, data: formattedSelect(suppliers, "id", "name") },
    }));
  });

  const fetchJobComputedPrice = useAsync(() => {
    const locationFee = location.data?.areaIsOutOfArea ? locationAreaCustomFee : null;
    const destinationFee = destination.data?.areaIsOutOfArea ? destinationAreaCustomFee : null;

    fetchJobComputedPriceAsync(
      contract.data,
      customer.data,
      customerItem.value,
      flow.value,
      location.value,
      destination.value,
      locationFee,
      destinationFee,
      setSingleCurrent,
      handleApiResponse,
      locationCustomAreaCode,
      destinationCustomAreaCode
    );
  });

  const fetchCustomerJobContracts = useAsync(() => fetchCustomerJobContractsAsync(customer.value, setSingleData));

  const fetchCustomerContractLocations = useAsync(() =>
    fetchCustomerContractLocationsAsync(customer.value, contract.value, setData)
  );

  const fetchLastCustomerItem = useAsync(() =>
    fetchLastCustomerItemAsync(contract.data.id, location.data.id, setSingleCurrent)
  );

  const fetchAndValidateStripeCustomerPaymentMethod = useAsync(() =>
    fetchAndValidateStripeCustomerPaymentMethodAsync(
      customer.data,
      contract.data,
      setSingleStripe,
      setSingleCurrent,
      notifier
    )
  );

  useEffect(() => {
    // Fetch the customers with job and suppliers when opening the modal quick job
    fetchInitialData.run();
  }, []);

  useEffect(() => {
    // Whenever customers are loading, update its state to show it's loading
    setSingleData("customers", fetchInitialData.loading, "loading");
  }, [fetchInitialData.loading]);

  useEffect(() => {
    // Whenever accounting-items are loading, update its state to show it's loading
    setSingleData("contracts", fetchCustomerJobContracts.loading, "loading");
  }, [fetchCustomerJobContracts.loading]);

  useEffect(() => {
    // Whenever locations are loading, update locations & destinations states to show it's loading
    setData((prevState) => ({
      ...prevState,
      locations: { ...prevState.locations, loading: fetchCustomerContractLocations.loading },
      destinations: { ...prevState.destinations, loading: fetchCustomerContractLocations.loading },
    }));
  }, [fetchCustomerContractLocations.loading]);

  // Handles the validation of each desired fields in the modal quick job
  useEffect(() => {
    const validatableFields = [
      "customer",
      "contract",
      "location",
      "customerItem",
      "flow",
      "unit",
      "dropPointPrimary",
      "dropPointSecondary",
      "destination",
      "locationAreaCustomFee",
      "destinationAreaCustomFee",
    ];
    const contractPaymentType = current.contract.data?.paymentTypeCode;
    const locationAreaIsOutOfArea = current.location.data?.areaIsOutOfArea && contractPaymentType === "CREDIT_CARD";
    const destinationAreaIsOutOfArea =
      current.destination.data?.areaIsOutOfArea && contractPaymentType === "CREDIT_CARD";

    // If a field was marked as an error but now it's truthy, remove the error
    validatableFields.forEach((key) => {
      if (current[key]?.value) {
        errors[key] && setSingleError(key, false);
      }

      // locationAreaCustomFee isn't an object so it doesn't have a `value` property like others
      if (key === "locationAreaCustomFee" && locationAreaIsOutOfArea && current[key] !== null) {
        errors[key] && setSingleError(key, false);
      }

      // destinationAreaCustomFee isn't an object so it doesn't have a `value` property like others
      if (key === "destinationAreaCustomFee" && destinationAreaIsOutOfArea && current[key] !== null) {
        errors[key] && setSingleError(key, false);
      }
    });
  }, [
    customer,
    contract,
    location,
    customerItem,
    flow,
    unit,
    dropPointPrimary,
    dropPointSecondary,
    destination,
    locationAreaCustomFee,
    destinationAreaCustomFee,
  ]);

  // Handle the effects related to the customer select
  useEffect(() => {
    // Whenever the selected customer changes, reset the current contract and paymentMethods to null if we have one selected
    setStripe(initialStripeState);

    let updates = {};
    if (contract.data) updates = { ...updates, contract: initialCurrentState };
    if (repeat) updates = { ...updates, repeat: 1 };
    if (paymentMethod) updates = { ...updates, paymentMethod: null };

    setCurrent((prevState) => ({ ...prevState, ...updates }));

    // Whenever the selected customer changes and it's valid, fetch its job accounting-items and paymentMethods
    customer.data && fetchCustomerJobContracts.run();
  }, [customer.data]);

  // Handle the effects related to the contract select
  useEffect(() => {
    // Whenever the current contract changes and it's valid, fetch its locations
    if (contract.data) {
      fetchCustomerContractLocations.run();
      fetchAndValidateStripeCustomerPaymentMethod.run();
    }

    // Whenever the current contract changes, reset the location, customer item and job note to null if filled
    jobNote && setSingleCurrent("jobNote", null);
    location.data && setSingleCurrent("location", initialCurrentState);
    customerItem.data && setSingleCurrent("customerItem", initialCurrentState);

    // Whenever the current contract changes and it's falsy, remove locations and customer items.
    // Most common case for this to happen is when we change the current customer.
    if (!contract.data) {
      locations && setSingleData("locations", initialDataState);
      customerItems && setSingleData("customerItems", initialDataState);
    }
  }, [contract.data]);

  // Handle the effects related to the customerItem select
  useEffect(() => {
    // Whenever the customerItem changes, reset the flow to null if selected
    flow.data && setSingleCurrent("flow", initialCurrentState);

    // Whenever the customerItem changes and it's null, reset the dropPoints to null if selected
    if (!customerItem.data) {
      let updates = {};

      if (destination.data) updates = { ...updates, destination: initialCurrentState };
      if (dropPointPrimary.data) updates = { ...updates, dropPointPrimary: initialCurrentState };
      if (dropPointSecondary.data) updates = { ...updates, dropPointSecondary: initialCurrentState };

      setCurrent((prevState) => ({ ...prevState, ...updates }));
    }
  }, [customerItem.data]);

  // Handle the effects related to the location
  useEffect(() => {
    if (!location.data) {
      let updates = {};

      if (locationNote) updates = { ...updates, locationNote: null };
      if (purchaseOrderNo) updates = { ...updates, purchaseOrderNo: null };
      if (customerMobilePhoneNumber) updates = { ...updates, customerMobilePhoneNumber: null };
      if (lastCustomerItemName) updates = { ...updates, lastCustomerItemName: null };
      if (locationAreaCustomFee) updates = { ...updates, locationAreaCustomFee: null };
      if (destinationAreaCustomFee) updates = { ...updates, destinationAreaCustomFee: null };
      if (locationCustomAreaCode) updates = { ...updates, locationCustomAreaCode: null };
      if (destinationCustomAreaCode) updates = { ...updates, destinationCustomAreaCode: null };
      if (notificationContacts) updates = { ...updates, notificationContacts: initialNotificationContactState };

      // Batch update all at once instead of multiple single state updates
      setCurrent((prevState) => ({ ...prevState, ...updates }));
    }

    // Whenever the selected location changes and it's valid, set location note,
    // purchase Order No and fetch its previous customer item
    if (location.data) {
      let updates = {
        locationNote: location.data.note,
        purchaseOrderNo: location.data.purchaseOrderNo,
        notificationContacts: isArrayTruthy(location.data.notificationContacts)
          ? mapResponsesNotificationContacts(location.data.notificationContacts)
          : initialNotificationContactState,
        customerMobilePhoneNumber: location.data.customerMobilePhoneNumber,
      };

      setCurrent((prevState) => ({ ...prevState, ...updates }));

      // Whenever the current location changes and it's valid, fetch its last customer item
      fetchLastCustomerItem.run();
    }
  }, [location.data]);

  // Handle the effects related to the flow
  useEffect(() => {
    let updates = {};

    updates = { ...updates, dropPointPrimary: initialCurrentState };
    if (current.price) updates = { ...updates, price: null };
    if (flow.data?.code !== "RE" && destination.data) {
      updates = { ...updates, destination: initialCurrentState, destinationAreaCustomFee: null };
    }

    flow.data?.code === "RE" && locationCustomAreaCode && setSingleCurrent("locationCustomAreaCode", null);

    setCurrent((prevState) => ({ ...prevState, ...updates }));
  }, [flow.data]);

  useEffect(() => {
    // When we select a flow that is not 'déplacer' and we have a location, compute the price. If both
    // of the conditions are still true and we change custom area fee, recompute the price again
    if (flow.data && flow.data.code !== "RE" && location.data) {
      destinationCustomAreaCode && setSingleCurrent("destinationCustomAreaCode", null);
      fetchJobComputedPrice.run();
    }
  }, [flow.data, location.data, locationAreaCustomFee, locationCustomAreaCode]);

  useEffect(() => {
    // If we have a destination and flow is 'déplacer', compute the price
    if (flow.data && flow.data.code === "RE" && destination.data) {
      fetchJobComputedPrice.run();
    }
  }, [flow.data, destination.data, destinationAreaCustomFee, destinationCustomAreaCode]);

  /**
   * Handler function to update current state, but just a specific property or a nested property
   */
  const setSingleCurrent = useCallback((state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      setCurrent((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      setCurrent((prevState) => ({ ...prevState, [state]: value }));
    }
  }, []);

  const setSingleNestedCurrent = useCallback((state, key, nestedState, value) => {
    // Since state is an array of object, we want to allow updating of nested properties
    setCurrent((prevState) => ({
      ...prevState,
      [state]: {
        ...prevState[state],
        [key]: {
          ...prevState[state][key],
          [nestedState]: value,
        },
      },
    }));
  }, []);

  /**
   * Handler function to update data state, but just a specific property or a nested property
   */
  const setSingleData = useCallback((state, value, nestedState = null) => {
    // Since state is an object, we want to allow updating of nested properties
    if (nestedState) {
      setData((prevState) => ({ ...prevState, [state]: { ...prevState[state], [nestedState]: value } }));
    } else {
      setData((prevState) => ({ ...prevState, [state]: value }));
    }
  }, []);

  const setSingleError = useCallback(
    (state, value) => setErrors((prevState) => ({ ...prevState, [state]: value })),
    []
  );

  const setSingleStripe = useCallback(
    (state, value) => setStripe((prevState) => ({ ...prevState, [state]: value })),
    []
  );

  const handlers = useMemo(
    () => ({
      setData,
      setCurrent,
      setErrors,
      setSingleData,
      setSingleCurrent,
      setSingleError,
      setSingleStripe,
      setSingleNestedCurrent,
    }),
    []
  );

  return { data, current, stripe, errors, handlers };
}
