import { Button, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { withCustomerLocations, withRoute } from "optigo-redux";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "@utils/withRouter";
import LoaderSpinner from "./LoaderSpinner";
import DialogWrapper from "./ui/DialogWrapper";
import FlexRowWrapper from "./ui/FlexRowWrapper";
import FormGroupWrapper from "./ui/FormGroupWrapper";
import HalfFormControl from "./ui/HalfFormControl";
import SelectHalfUi from "./ui/SelectHalf";
import TextFieldUi from "./ui/TextField";
import { getErrorMessage, handleChangeFields } from "@utils/form";
import TextAreaUi from "./ui/TextArea";

const initialState = {
  errors: {
    adr1: false,
    adr2: false,
    city: false,
    doorNo: false,
    note: false,
    postalCode: false,
    province: false,
  },
  adr1: "",
  adr2: "",
  city: "",
  doorNo: "",
  name: "",
  note: "",
  purchaseOrderNo: "",
  postalCode: "",
  province: "QC",
  fileNumber: "",
  showSpinner: false,
};

const provinces = [
  { name: <Trans i18nKey="provinces.ab" />, code: "AB" },
  { name: <Trans i18nKey="provinces.bc" />, code: "BC" },
  { name: <Trans i18nKey="provinces.pe" />, code: "PE" },
  { name: <Trans i18nKey="provinces.mb" />, code: "MB" },
  { name: <Trans i18nKey="provinces.nb" />, code: "NB" },
  { name: <Trans i18nKey="provinces.ns" />, code: "NS" },
  { name: <Trans i18nKey="provinces.on" />, code: "ON" },
  { name: <Trans i18nKey="provinces.qc" />, code: "QC" },
  { name: <Trans i18nKey="provinces.sk" />, code: "SK" },
  { name: <Trans i18nKey="provinces.nl" />, code: "NL" },
  { name: <Trans i18nKey="provinces.nu" />, code: "NU" },
  { name: <Trans i18nKey="provinces.nt" />, code: "NT" },
  { name: <Trans i18nKey="provinces.yt" />, code: "YT" },
  { name: <Trans i18nKey="provinces.fl" />, code: "FL" },
];

class ModalCustomerLocation extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      ...initialState,
      ...props.customerLocation,
    };
  }

  get valid() {
    const { postalCode, fileNumber } = this.state;
    const errors = { ...initialState.errors };
    let valid = true;

    for (const name of ["adr1", "city", "doorNo", "province"]) {
      if (!this.state[name].trim()) {
        valid = false;
        errors[name] = true;
      }
    }

    const canadianPostalCode = new RegExp(
      /[abceghjklmnprstvxy][0-9][abceghjklmnprstvwxyz]\s?[0-9][abceghjklmnprstvwxyz][0-9]/i
    );

    if (!canadianPostalCode.test(postalCode)) {
      valid = false;
      errors.postalCode = true;
      this.setState({ errorMessage: true });
    } else {
      this.setState({ errorMessage: false });
    }

    const validFileNumber = new RegExp(/^[0-9\b]+$/);

    // Only run file number validation if the field is not empty
    if (fileNumber && !validFileNumber.test(fileNumber)) {
      valid = false;
      errors.fileNumber = true;
      this.setState({ errorMessage: true });
    }
    this.setState({ errors });

    return valid;
  }

  getErrorMessage = getErrorMessage.bind(this);

  handleChangeFields = handleChangeFields.bind(this);

  handleClose = () => {
    this.setState(initialState);
    this.setState({ showSpinner: false });
    this.props.onClose();
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { route } = this.props;
    const routeId = route.id;

    if (this.valid) {
      this.setState({ showSpinner: true });

      const {
        callback,
        contractId,
        customerId,
        customerLocation,
        createCustomerLocation,
        editLocation,
        createRouteLocation,
        routeId,
        refreshList,
        typeLocation,
      } = this.props;

      const { adr1, adr2, city, doorNo, name, note, postalCode, province, fileNumber, purchaseOrderNo } = this.state;

      const { id } = customerLocation;

      const additionalParam = routeId ? true : id;
      // eslint-disable-next-line no-nested-ternary
      const method = routeId ? createRouteLocation : id ? editLocation : createCustomerLocation;
      const editedId = routeId || customerId;
      const editedContractId = routeId ? null : contractId;
      const fullAddress = doorNo + " " + adr1 + ", " + city + ", " + province + ", " + postalCode;

      const callbackParam = await method(
        editedId,
        {
          adr_1: adr1,
          adr_2: adr2,
          contract_id: editedContractId,
          door_no: doorNo,
          postal_code: postalCode,
          city,
          name: name || fullAddress,
          note,
          province,
          purchase_order_no: purchaseOrderNo,
          file_number: fileNumber,
          typeLocation,
        },
        additionalParam
      );

      this.setState(initialState);
      callback(callbackParam);
      refreshList();
    }
  };

  renderMenuItems = (label, data) => [
    <MenuItem key="-1" value="-1">
      {label}
    </MenuItem>,

    ...data.map(({ name, code }) => (
      <MenuItem key={code} value={code}>
        {name}
      </MenuItem>
    )),
  ];

  handleChangePostalCode = (event) => {
    const trimStartPostalCode = event.target.value.trimStart();
    const completedTrimPostalCode = trimStartPostalCode.trimEnd();
    this.setState({ postalCode: completedTrimPostalCode });
  };

  render() {
    const { actionName } = this.props;
    const {
      adr1,
      adr2,
      city,
      doorNo,
      errors,
      name,
      note,
      postalCode,
      province,
      fileNumber,
      purchaseOrderNo,
      showSpinner,
    } = this.state;

    const modalTitle =
      actionName.props.id === "add" ? <Trans i18nKey="add_location" /> : <Trans i18nKey="edit_location" />;

    return (
      <DialogWrapper onClose={this.handleClose} open={this.props.open}>
        {showSpinner && <LoaderSpinner text={"Création de la l'emplacement..."} />}
        <DialogTitle>{modalTitle}</DialogTitle>

        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <FormGroupWrapper>
              <TextFieldUi
                inputProps={{ maxLength: 100 }}
                fullWidth
                id="cpbr-name"
                label={<Trans i18nKey="address.name" />}
                onChange={this.handleChangeFields("name")}
                value={name || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 10 }}
                    error={errors.doorNo}
                    fullWidth
                    helperText={this.getErrorMessage("doorNo")}
                    id="cpbr-door-no"
                    label={<Trans i18nKey="address.door_no" />}
                    onChange={this.handleChangeFields("doorNo")}
                    value={doorNo.trim() || ""}
                  />
                </HalfFormControl>

                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 7 }}
                    error={errors.postalCode}
                    fullWidth
                    helperText={<Trans i18nKey="validate_postal_code" />}
                    id="cpbr-postal-code"
                    label={<Trans i18nKey="address.postal_code" />}
                    onChange={this.handleChangePostalCode}
                    value={postalCode.trim() || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                inputProps={{ maxLength: 50 }}
                error={errors.adr1}
                fullWidth
                helperText={this.getErrorMessage("adr1")}
                id="cpbr-adr1"
                label={<Trans i18nKey="address.adr_1" />}
                onChange={this.handleChangeFields("adr1")}
                value={adr1 || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextFieldUi
                inputProps={{ maxLength: 50 }}
                error={errors.adr2}
                fullWidth
                helperText={this.getErrorMessage("adr2")}
                id="cpbr-adr2"
                label={<Trans i18nKey="address.adr_2" />}
                onChange={this.handleChangeFields("adr2")}
                value={adr2 || ""}
              />
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 45 }}
                    error={errors.city}
                    fullWidth
                    helperText={this.getErrorMessage("city")}
                    id="cpbr-city"
                    label={<Trans i18nKey="address.city" />}
                    onChange={this.handleChangeFields("city")}
                    value={city || ""}
                  />
                </HalfFormControl>

                <SelectHalfUi
                  className="cpbr-province-select"
                  formControlError={errors.province}
                  formHelperErrorMsg={this.getErrorMessage("province")}
                  id="cpbr-province"
                  inputLabelText={<Trans i18nKey="address.province" />}
                  onChange={this.handleChangeFields("province")}
                  value={`${province || ""}`}
                >
                  {this.renderMenuItems(<Trans i18nKey="select_province" />, provinces)}
                </SelectHalfUi>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <FlexRowWrapper>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                    id="cpbr-file-number"
                    label={<Trans i18nKey="location.file_number" />}
                    onChange={this.handleChangeFields("fileNumber")}
                    value={fileNumber || ""}
                  />
                </HalfFormControl>
                <HalfFormControl>
                  <TextFieldUi
                    inputProps={{ maxLength: 255 }}
                    className="cpbr-purchase-order-no"
                    id="cpbr-purchase-order-no"
                    label={<Trans i18nKey="purchase_order_no" />}
                    onChange={this.handleChangeFields("purchaseOrderNo")}
                    value={purchaseOrderNo || ""}
                  />
                </HalfFormControl>
              </FlexRowWrapper>
            </FormGroupWrapper>

            <FormGroupWrapper>
              <TextAreaUi
                className="cpbr-note"
                error={errors.note}
                helperText={this.getErrorMessage("note")}
                id="cpbr-note"
                label={<Trans i18nKey="address.note" />}
                onChange={this.handleChangeFields("note")}
                value={note || ""}
              />
            </FormGroupWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.handleClose}>
            <Trans i18nKey="cancel" />
          </Button>

          <Button onClick={this.handleSubmit} variant="contained">
            {actionName}
          </Button>
        </DialogActions>
      </DialogWrapper>
    );
  }
}

ModalCustomerLocation.defaultProps = {
  contractId: null,
  customerId: null,
  customerLocation: {},
  routeId: null,
  refreshList: () => { },
};

ModalCustomerLocation.propTypes = {
  actionName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  callback: PropTypes.func.isRequired,
  contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerLocation: PropTypes.object,
  createCustomerLocation: PropTypes.func.isRequired,
  createRouteLocation: PropTypes.func.isRequired,
  editLocation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  routeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  refreshList: PropTypes.func.isRequired,
  route: PropTypes.object,
};

export default withRouter(withRoute(withCustomerLocations(ModalCustomerLocation)));
