import { useQuery, UseQueryResult } from "@tanstack/react-query";
import customer from "@hooks/customers/customer.keys";
import { Customer } from "@@types/customers/customer.type";
import fetchShowCustomer from "../../services/customers/fetch-customer.service";
import mapResponseToCustomer from "@@types/customers/mappers/mapResponseToCustomer.mapper";

const useFetchShowCustomer = (customerId: string): UseQueryResult<Customer | undefined> => {
  return useQuery({
    queryKey: customer.detail(customerId),
    queryFn: () => fetchShowCustomer(customerId),
    select: ({ data: response }): Customer | undefined => mapResponseToCustomer(response),
  });
};

export default useFetchShowCustomer;
