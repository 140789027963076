import React, { ReactElement } from "react";
import { Paper, Table, TableBody, TableContainer, TableHead } from "@mui/material";

import { FinancialReportData } from "@@types/reports/financial-report.type";
import FinancialReportTableHeader from "@components/report/financial-report-table/FinancialReportTableHeader";
import FinancialReportTableBody from "@components/report/financial-report-table/FinancialReportTableBody";
import useFinancialReportTable from "@components/report/hooks/financial-report-table.hook";
import { Report } from "@components/report/hooks/report-layout.hook";
import ExportReportToXlsxButton from "@components/report/buttons/ExportReportToXlsxButton";
import { Customer } from "@@types/customers/customer.type";

type FinancialReportTableProps = {
  customer: Customer;
  report: FinancialReportData[];
  reportSettings: Report;
  isLoading: boolean;
};

const FinancialReportTable = ({
  customer,
  report,
  reportSettings,
  isLoading,
}: FinancialReportTableProps): ReactElement => {
  const { handleClickOnCollapseRow, collapseRows, handleClickExportFinancialReportToXlsx, xlsxFileIsPending } =
    useFinancialReportTable(reportSettings, customer.id.toString());
  return (
    <TableContainer component={Paper}>
      {!isLoading && (
        <ExportReportToXlsxButton
          onClick={handleClickExportFinancialReportToXlsx}
          isLoading={xlsxFileIsPending}
          label={"Télécharger rapport financier"}
        />
      )}
      <Table aria-label="collapsible table">
        <TableHead>
          <FinancialReportTableHeader />
        </TableHead>
        <TableBody>
          <FinancialReportTableBody
            report={report}
            onClick={handleClickOnCollapseRow}
            isLoading={isLoading}
            collapseRows={collapseRows}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinancialReportTable;
