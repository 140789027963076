import { Button, CircularProgress, Stack } from "@mui/material";
import React, { ReactElement } from "react";

type ReportDateSelectorProps = {
  onClick: () => void;
  isLoading: boolean;
};

const ReportCreateButton = ({ onClick, isLoading }: ReportDateSelectorProps): ReactElement => {
  return (
    <Stack
      direction="row"
      p={2}
      spacing={2}
    >
      <Button
        variant="contained"
        sx={{ background: "#006F9C", width: 170 }}
        onClick={onClick}
        disabled={isLoading}
      >
        Créer rapport
        {isLoading && <CircularProgress style={{ width: 15, height: 15 }} />}
      </Button>
    </Stack>
  );
};

export default ReportCreateButton;
