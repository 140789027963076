// React imports
import React, { ReactElement } from "react";
// Externals imports

import { MasterRouteReport } from "@@types/reports/master-route-report.type";
import MasterRoutesReportParentRow from "@components/report/master-routes-report-table/MasterRoutesReportParentRow";

type MasterRoutesReportTableBodyProps = {
  isLoading: boolean;
  report: MasterRouteReport[];
};

const MasterRoutesReportTableBody = ({
  isLoading,
  report,
}: MasterRoutesReportTableBodyProps): ReactElement | ReactElement[] => {
  if (!isLoading && !report) {
    return <span>** Aucune donnée disponible **</span>;
  }

  return report.map((row) => {
    return (
      <MasterRoutesReportParentRow
        key={row.fileNumber}
        row={row}
      />
    );
  });
};

export default MasterRoutesReportTableBody;
