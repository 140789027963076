// TODO Change .js to .ts

import {
  Drawer,
  Divider as MuiDivider,
  ListItemText,
  List as MuiList,
  Collapse,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
} from "@mui/material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import ListIcon from "@mui/icons-material/List";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import PeopleIcon from "@mui/icons-material/People";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PowerSettingIcon from "@mui/icons-material/PowerSettingsNew";
import SalesIcon from "@mui/icons-material/BusinessCenter";
import React, { useState, useEffect, useCallback } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { withRouter } from "@utils/withRouter";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import LogoVilleco from "@assets/images/logo-villeco.png";
import withUser from "@utils/withUser";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useLogout from "@hooks/auth/useLogout";
import FireTruckIcon from "@mui/icons-material/FireTruck";
import PepMenuItems from "@components/header/PepMenuItems";
import * as API from "@services";
import useAsync from "@hooks/useAsync";

const Wrapper = styled.div`
  width: 280px;
`;

const Divider = styled(MuiDivider)`
  && {
    margin: 15px 0;
  }
`;

export const List = styled(MuiList)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ListItemButton = styled(MuiListItemButton)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

    svg {
      margin-right: 0;
    }
  }
`;

const Logo = styled.img`
  width: 140px;
`;

function Menu(props) {
  const { mutateAsync: logout } = useLogout();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [contractRoutes, setContractRoutes] = useState([]);
  const { opened, toggleMenu, disablePrices } = props;

  const fetchContractRoutes = useAsync(async () => {
    const res = await API.ContractRoute.fetchContractRoutes();
    setContractRoutes(res.map((item) => item.attributes));
  });

  useEffect(() => {
    fetchContractRoutes.run();
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await logout(
        {},
        {
          onSuccess: () => {
            window.location.href = "/login";
          },
        }
      );
    } catch (error) {
      alert("Erreur lors de la tentative de déconnexion. Veuillez réessayer.");
    }
  }, []);

  return (
    <Drawer
      open={opened}
      onClose={toggleMenu}
    >
      <Wrapper
        tabIndex={0}
        role="button"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
      >
        <List component="nav">
          {/* Villéco logo */}
          <ListItemButton
            component={Link}
            to="/"
          >
            <Logo src={LogoVilleco} />
          </ListItemButton>

          <Divider />

          {/* Répartition button */}
          <ListItemButton
            component={Link}
            to="/"
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <DashboardIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="common.title.assignation" />} />
          </ListItemButton>

          {/* <ListItem component={Link} to="/preparations" button> */}
          {/*  <AssignmentIcon color='secondary' /> */}
          {/*  <ListItemText primary={<Trans i18nKey="common.title.preparation" />} /> */}
          {/* </ListItem> */}

          {/* Clients button */}
          <ListItemButton
            component={Link}
            to="/customers"
            data-cy="customersListButton"
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <PeopleIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="common.title.client" />} />
          </ListItemButton>

          {/* Inventaire button */}
          <ListItemButton
            component={Link}
            to="/items"
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <ListIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="common.title.inventory" />} />
          </ListItemButton>

          {/* Units button */}
          <ListItemButton
            component={Link}
            to="/units"
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <LocalShippingIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="common.title.units" />} />
          </ListItemButton>

          {/* Roll-off button */}
          <ListItemButton
            component={Link}
            to="/roll-off-lifting-requests"
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <FireTruckIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="common.title.roll_off" />} />
          </ListItemButton>
        </List>
      </Wrapper>

      <Wrapper>
        <List>
          {/* Routes dropdown button */}
          <ListItemButton onClick={() => setOpenDropDown((prevState) => !prevState)}>
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <TimeToLeaveIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="routes.title" />} />
            {openDropDown ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse
            key={1}
            in={openDropDown}
            timeout="auto"
            unmountOnExit
          >
            <List
              component="li"
              disablePadding
              key={1}
              onClick={toggleMenu}
              style={{
                overflow: "auto",
                maxHeight: 300,
              }}
            >
              {contractRoutes.map((item) => (
                <ListItemButton
                  key={item.id}
                  component={Link}
                  to={`/customers/${item.customerId}/contracts/${item.contractId}/preparations/${item.customerItemId}?current_tab=3`}
                >
                  <ListItemText
                    key={item.id}
                    primary={item.routeName}
                    sx={{ textAlign: "center" }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
      </Wrapper>

      <Divider />

      <Wrapper>
        <PepMenuItems closeMenu={toggleMenu} />
      </Wrapper>

      <Divider />

      <Wrapper
        tabIndex={0}
        role="button"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
      >
        <List component="nav">
          {/* Liste de prix button */}
          <ListItemButton
            component={Link}
            to="/prices"
            data-cy="pricesListButton"
            disabled={disablePrices}
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <RequestQuoteIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Liste de prix" />
          </ListItemButton>

          {/* Ventes button */}
          <ListItemButton
            component={Link}
            to="/transactions"
            data-cy="salesListButton"
          >
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <SalesIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Transactions" />
          </ListItemButton>

          {/* Utilisateurs button */}
          {/* {isUserAdmin && (
            <ListItemButton component={Link} to="/users" data-cy="usersListButton">
              <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
                <SupervisedUserCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Utilisateurs" />
            </ListItemButton>
          )} */}

          {/* Déconnexion button */}
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
              <PowerSettingIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={<Trans i18nKey="logout" />} />
          </ListItemButton>
        </List>
      </Wrapper>
    </Drawer>
  );
}

Menu.defaultProps = {
  disablePrices: true,
  opened: false,
};

Menu.propTypes = {
  disablePrices: PropTypes.bool,
  opened: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
};

export default withRouter(withUser(Menu));
