import { useParams } from "react-router";
import { MatterCodes } from "@@types/matter/matter";

interface CustomParams extends Record<string, string | undefined> {
  administrativeSectorId: string;
  contractId: string;
  customerId: string;
  customerItemId: string;
  locationId: string;
  masterCollectionSectorId: string;
  masterRouteId: string;
  matterCode: MatterCodes;
  routeId: string;
  routeTemplateId: string;
  sectorDetailId: string;
  sectorId: string;
}

/**
 * Overrides the useParams from React router to avoid undefined
 */
export const useCustomParams = (): CustomParams => {
  const {
    administrativeSectorId,
    contractId,
    customerId,
    customerItemId,
    locationId,
    masterCollectionSectorId,
    masterRouteId,
    matterCode,
    routeId,
    routeTemplateId,
    sectorDetailId,
    sectorId,
  } = useParams<CustomParams>();

  return {
    administrativeSectorId: administrativeSectorId || "",
    contractId: contractId || "",
    customerId: customerId || "",
    customerItemId: customerItemId || "",
    locationId: locationId || "",
    masterCollectionSectorId: masterCollectionSectorId || "",
    masterRouteId: masterRouteId || "",
    matterCode: matterCode || "D",
    routeId: routeId || "",
    routeTemplateId: routeTemplateId || "",
    sectorDetailId: sectorDetailId || "",
    sectorId: sectorId || "",
  };
};

export default useCustomParams;
