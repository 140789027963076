// React imports
import React, { ReactElement, ReactNode } from "react";
// External imports
import { TableCell, TableRow } from "@mui/material";

export type HeaderLabel = {
  [name: string]: { label: ReactNode };
};

const headerLabel: HeaderLabel = {
  fileNumber: { label: "No. Dossier" },
  district: { label: "Arrondissement" },
  date: { label: "Date" },
  hour: { label: "Heure" },
  doorNo: { label: "No. civique" },
  street: { label: "Rue" },
  companyName: { label: "Commerce" },
  isCcav: { label: "Type" },
  matter: { label: "Matière" },
  containerNumber: { label: "No. Conteneur" },
  remark: { label: "Remarques" },
  addedComment: { label: "Commentaire Supplémentaire" },
  isNoticeGiven: { label: "Avis remis" },
  photos: { label: "Photos" },
  isCollected: { label: "Collecté" },
  unitName: { label: "Camion" },
  cityComment: { label: "Commentaire (ville)" },
};

const header: string[] = [
  "fileNumber",
  "district",
  "date",
  "hour",
  "doorNo",
  "street",
  "companyName",
  "isCcav",
  "matter",
  "containerNumber",
  "remark",
  "addedComment",
  "isNoticeGiven",
  "photos",
  "isCollected",
  "unitName",
  "cityComment",
];

const ProblemCasesReportTableHeader = (): ReactElement => {
  return (
    <TableRow>
      {header.map((name) => (
        <TableCell key={name}>{headerLabel[name].label}</TableCell>
      ))}
      <TableCell />
    </TableRow>
  );
};

export default ProblemCasesReportTableHeader;
