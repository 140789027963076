// @ts-check

import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "@context/auth/AuthProvider";
import Layout from "@components/layout/Layout";

const PrivateRoute = () => {
  const { auth } = useAuth();

  if (!auth.user)
    return (
      <Navigate
        to="login"
        replace
      />
    );

  return <Layout />;
};

export default PrivateRoute;
