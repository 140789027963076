// React imports
import React, { ReactElement } from "react";
// Externals imports

import { Stack, TableCell, TableRow } from "@mui/material";
import { ProblemCaseReport } from "@@types/reports/problem-case-report.type";
import { isArrayTruthy } from "@utils/common";

type ProblemCasesReportTableBodyProps = {
  isLoading: boolean;
  report: ProblemCaseReport[];
};

const ProblemCasesReportTableBody = ({
  isLoading,
  report,
}: ProblemCasesReportTableBodyProps): ReactElement | ReactElement[] => {
  // if (!isLoading && !report) {
  //   return <span>** Aucune donnée disponible **</span>;
  // }

  return report.map((row) => {
    const key = `${row.fileNumber}-${row.date}-${row.hour}`;
    return (
      <TableRow key={key}>
        <TableCell align="center">{row.fileNumber || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.district || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.date || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.hour || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.doorNo || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.street || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.companyName || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.isCcav ? "Ch. avant" : "Roll-Off"}</TableCell>
        <TableCell align="center">{row.matter || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.containerNumber || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.remark}</TableCell>
        <TableCell align="center">{row.addedComment}</TableCell>
        <TableCell align="center">{row.isNoticeGiven ? "Oui" : "Non"}</TableCell>
        <TableCell align="center">
          <Stack spacing={1}>
            {isArrayTruthy(row.photos)
              ? row.photos.map((photo) => {
                  return (
                    <a
                      key={photo}
                      href={photo}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Github"
                    >
                      {photo ? "Oui" : "N/A"}
                    </a>
                  );
                })
              : "N/A"}
          </Stack>
        </TableCell>
        <TableCell align="center">{row.isCollected ? "Oui" : "Non"}</TableCell>
        <TableCell align="center">{row.unitName || "INCONNUE"}</TableCell>
        <TableCell align="center">{row.cityComment}</TableCell>
      </TableRow>
    );
  });
};

export default ProblemCasesReportTableBody;
